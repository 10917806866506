import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient) {}

  cardList(paginationObj?: any, orderBy: string = 'DESC'): Observable<any> {
    let url = `${apiConfig.card}/list-cards`;
    if (paginationObj) {
      url =
        apiConfig.card +
        `/list-cards?page=${paginationObj.currentPage + 1}&pageSize=${
          paginationObj.pageSize
        }&orderBy=${orderBy.toUpperCase()}`;
    }
    return this.http.get<any>(url);
  }

  setDefaultCard(data: any): Observable<any> {
    return this.http.patch<any>(`${apiConfig.card}/change-default-card`, data);
  }

  addCard(data: any): Observable<any> {
    return this.http.post<any>(`${apiConfig.card}/add-card`, data);
  }

  deleteCard(id: string): Observable<any> {
    return this.http.delete<any>(`${apiConfig.card}/${id}`);
  }
}
