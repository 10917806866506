import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISidebarItem } from 'src/app/_types/common.types';
import {
  corporateSidebarList,
  superAdminSidebarList,
} from 'src/app/constant/sidebar-menu-label.constants';
import { SharedDataService } from '../../services/shared-data.service';
import { payment_mode } from 'src/app/constant/db.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  confirmPassword = false;
  corporateHeaders: ISidebarItem[] = corporateSidebarList;
  superAdminHeaders: ISidebarItem[] = superAdminSidebarList;
  isCollapsed: boolean = false;
  isAdmin: boolean = false;
  pathName: string = '';
  sidebarListByRoles: ISidebarItem[] = [];
  localStorageData: any;

  constructor(
    public router: Router,
    private _ActiveRoute: ActivatedRoute,
    private _SharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.pathName = this._ActiveRoute.snapshot.routeConfig?.path || '';
    this.isAdmin = this.pathName === 'admin';
    this.sidebarListByRoles = this.isAdmin
      ? superAdminSidebarList
      : corporateSidebarList;

    //to hide saved card tab for offline corporates
    if (!this.isAdmin) {
      this._SharedDataService.getSubsData().subscribe((data) => {
        const userData = data;
        if (userData?.payment_mode === payment_mode.OFFLINE) {
          this.sidebarListByRoles = corporateSidebarList.filter(item => item.name !== 'Saved Cards');
        } else {
          this.sidebarListByRoles = [...corporateSidebarList];
        }
      });
    }
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }
}
