<div class="layout-wrapper">
    <div class="d-flex layout-inner">
        <app-sidebar></app-sidebar>
        <div class="content-wrapper">
            <mat-card class="card-wrapper">
                <app-header></app-header>
                <mat-card-content>
                    <router-outlet></router-outlet>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <app-footer></app-footer>
</div>