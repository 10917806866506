import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICards, ICardsResponse } from 'src/app/_types/card.types';
import { CardService } from 'src/app/services/card.service';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { LoaderService } from './loader.service';
import { NotifierService } from './notifier.service';
import { SessionService } from './session.service';
import { StripePaymentService } from './stripe.service';
import { PaymentIntent } from 'src/app/_types/driver.types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddDriverProcessServiceTsService {
  userRole!: any;
  driverDataCalculations: any;
  cardList: ICards[] | null = [];
  private valueChangedSubject = new Subject<void>();

  constructor(
    private _SessionService: SessionService,
    private _ConfirmationDialogService: ConfirmationDialogService,
    private _LoaderService: LoaderService,
    private _Router: Router,
    private _StripePaymentService: StripePaymentService,
    private _CardService: CardService,
    private _NotifierService: NotifierService
  ) {}

  ngOnInit() {
    this.userRole = this._SessionService.getSession('user_role');
  }

  driverCalculations(addDriverData: PaymentIntent, message: string) {
    this.driverDataCalculations = addDriverData;
    this._ConfirmationDialogService
      .openDriverCalculationsDialog(
        `DELM8 - ${addDriverData.planDuration}`,
        'driverCalculations'
      )
      .subscribe((result) => {
        if (result) {
          this.openCardListModal(addDriverData);
        } else {
          this._LoaderService.setState(true);
          this._Router.navigate(['/corporate/driver/list']);
        }
      });
  }

  openCardListModal(addDriverData: PaymentIntent) {
    this._CardService.cardList().subscribe({
      next: (response: ICardsResponse) => {
        if (response.success) {
          this.cardList = response?.data;
          this._LoaderService.setState(false);
          //driver amount calculations
          const driverAmountSummary = {
            amount: addDriverData ? addDriverData.subTotal : '0',
            vat: addDriverData ? addDriverData.vatAmount : '0',
            totalAmount: addDriverData ? addDriverData.amount : '0',
          };
          this._ConfirmationDialogService
            .openCardListDialog(
              'Select card',
              'selectCard',
              this.cardList,
              driverAmountSummary
            )
            .subscribe((result: any) => {
              if (result) {
                this.handleOpenModal(
                  result,
                  addDriverData?.clientSecret,
                  addDriverData?.transactionId
                );
              } else {
                this._LoaderService.setState(false);
                this._Router.navigate(['/corporate/driver/list']);
              }
            });
        }
      },
      error: (error: any) => {
        this._NotifierService.showError(error.message);
      },
    });
  }

  handleOpenModal(
    optionData: any,
    clientSecret: string,
    transactionId: string
  ) {
    switch (optionData.option) {
      case 'addNewCard': {
        this._ConfirmationDialogService
          .openPayNowDialog(
            'Add Card Details',
            'cardDetails',
            clientSecret,
            transactionId,
            true,
            true
          )
          .subscribe((result) => {
            this._LoaderService.setState(true);
            if (result) {
              setTimeout(() => {
                this._Router.navigate(['/corporate/driver/list']);
                this._LoaderService.setState(false);
                this._NotifierService.showSuccess('Driver added successfully.');
              }, 2000);
            } else {
              this._LoaderService.setState(false);
              this._Router.navigate(['/corporate/driver/list']);
            }
          });
        break;
      }
      case 'payNow': {
        this._StripePaymentService
          .confirmPaymentByToken(clientSecret, optionData?.selectedCard?.id)
          .subscribe((paymentResult: any) => {
            if (paymentResult) {
              setTimeout(() => {
                this._Router.navigate(['/corporate/driver/list']);
                this._LoaderService.setState(false);
                this._NotifierService.showSuccess('Driver added successfully.');
              }, 2000);
            } else {
              this._LoaderService.setState(false);
              this.openCardListModal(this.driverDataCalculations);
              // this._Router.navigate(['/corporate/driver/list']);
            }
            this._SessionService.removeSessionStorage('driverData');
          });
        break;
      }
      default: {
        console.log('Default case triggered'); // Added log statement for debugging
        break;
      }
    }
  }

  // set driver calculation data from the subscription api response
  setDriverCalculationData(driverCalData: any) {
    this.driverDataCalculations = driverCalData;
  }

  //for re-calling the driverList component when successful bulk upload
  valueChanged$ = this.valueChangedSubject.asObservable();
  emitValueChanged() {
    this.valueChangedSubject.next();
  }
}
