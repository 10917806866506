import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  setSession(keyValue: { [key: string]: string }): void {
    for (const prop in keyValue) {
      if (keyValue.hasOwnProperty(prop)) {
        window.localStorage.removeItem(prop);
        window.localStorage.setItem(prop, ['user_role', 'access_token'].includes(prop) ?  keyValue[prop] : JSON.stringify(keyValue[prop]));
      }
    }
  }

  getSession(key: string): string {    
    return window.localStorage.getItem(key) ? ['user_role', 'access_token'].includes(key) ? window.localStorage.getItem(key) : JSON.parse(window.localStorage.getItem(key)|| '') : '';
  }

  removeSessions(key: string): void {
    window.localStorage.removeItem(key);
  }
  setCookieSession(): void {}

  setSessionStorage(keyValue: { [key: string]: string }): void {
    const expiryTime = new Date().getTime() + 300 * 1000;
    for (const prop in keyValue) {
      if (keyValue.hasOwnProperty(prop)) {
        sessionStorage.removeItem(prop);
        sessionStorage.setItem(prop, keyValue[prop]);
      }
    }
    sessionStorage.setItem('expiryTime', JSON.stringify(expiryTime));
  }

  getSessionStorage(key: string): string | null {
    const expiryTime = sessionStorage.getItem('expiryTime');
    const now = new Date().getTime();
    if (now > JSON.parse(expiryTime!)) {
      this.removeSessionStorage('driverData');
    }
    return sessionStorage.getItem(key);
  }

  removeSessionStorage(key: string): void {
    return sessionStorage.removeItem(key);
  }

  removeLocalStorageData(){
    window.localStorage.clear();
  }
}
