// import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppLayputComponent } from './components/app-layput/app-layput.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
// import { PreLoginLayoutComponent } from './components/pre-login-layout/pre-login-layout.component';
import { CommonModule, DatePipe } from '@angular/common';
import { PreLoginLayoutComponent } from './components/pre-login-layout/pre-login-layout.component';

// form module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { InputComponent } from './components/input/input.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StatisticalDataComponent } from './components/statistical-data/statistical-data.component';
import { TableComponent } from './components/table/table.component';

import {
  NgxStripeModule,
  StripeElementsDirective,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { PaginatorDirective } from '../directives/paginator.directive';
import { NestedObjectValuePipe } from '../pipes/nestedObjectValue';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FloatingNotificationComponent } from './components/floating-notification/floating-notification.component';
import { InvoiceDetailsComponent } from './components/invoice-details/invoice-details.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TableControlsComponent } from './components/table-controls/table-controls.component';

@NgModule({
  declarations: [
    AuthHeaderComponent,
    HeaderComponent,
    AppLayputComponent,
    PreLoginLayoutComponent,
    FooterComponent,
    InputComponent,
    TableComponent,
    SidebarComponent,
    StatisticalDataComponent,
    LoaderComponent,
    FloatingNotificationComponent,
    TableControlsComponent,
    ConfirmationDialogComponent,
    DataGridComponent,
    SubscriptionComponent,
    DatepickerComponent,
    NestedObjectValuePipe,
    InvoiceDetailsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    NgxStripeModule.forRoot(environment.stripeKey),
    PaginatorDirective,
  ],
  exports: [
    AuthHeaderComponent,
    HeaderComponent,
    AppLayputComponent,
    PreLoginLayoutComponent,
    FooterComponent,
    InputComponent,
    LoaderComponent,
    TableComponent,
    MaterialModule,
    ReactiveFormsModule,
    StatisticalDataComponent,
    PaginatorDirective,
    FloatingNotificationComponent,
    TableControlsComponent,
    DatepickerComponent,
    DataGridComponent,
    NestedObjectValuePipe,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
