import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ServiceHeaderData } from 'src/app/_types/common.types';
import { UserData } from 'src/app/_types/corporator.types';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private subscriptionData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private headerData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public headerTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() {}

  //data: full user data (for subscription)
  setSubsData(data: UserData): void {
    this.subscriptionData.next(data);
  }
  getSubsData(): BehaviorSubject<any> {
    return this.subscriptionData;
  }

  //for header data
  setServiceHeaderData(headerData: ServiceHeaderData): void {
    this.headerData.next(headerData);
  }
  getServiceHeaderData(): BehaviorSubject<any> {
    return this.headerData;
  }

  //for user data
  setHeaderTitle(data: string): void {
    this.headerTitle.next(data);
  }
  getHeaderTitle(): BehaviorSubject<any> {
    return this.headerTitle;
  }

}
