import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { IError, IResponse } from 'src/app/_types/common.types';
import { IPlans } from 'src/app/_types/plan.types';
import {
  payment_mode,
  plan_duration,
  subscription_status,
} from 'src/app/constant/db.constant';
import { PlanService } from 'src/app/services/plan.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { LoaderService } from '../../services/loader.service';
import { NotifierService } from '../../services/notifier.service';
import { SessionService } from '../../services/session.service';
import { SharedDataService } from '../../services/shared-data.service';
import {
  IChangeSubscriptionPayload,
  ISubcriptionDetails,
} from 'src/app/_types/subscription.types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CardService } from 'src/app/services/card.service';
import { StripePaymentService } from '../../services/stripe.service';
import { ICards, ICardsResponse } from 'src/app/_types/card.types';
import { AddDriverProcessServiceTsService } from '../../services/add-driver-process.service';
import { PaymentIntent } from 'src/app/_types/driver.types';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent {
  subscriptionDetails!: ISubcriptionDetails;
  planList: IPlans[] = [];
  activeUpcomingPlanList: IPlans[] = [];
  corporateData: any;
  form!: FormGroup;
  otherPlanList: any = [
    {
      duration: 'custom',
    },
  ];
  cardList: ICards[] | null = [];
  isTooltipVisible: boolean = false;
  tooltipData: any[] = [];
  tooltipDataMap: {
    startDate: string | any;
    endDate: string | any;
  }[] = [];
  activeTooltipIndex: number = -1;
  driverPaymentIntentData!: PaymentIntent

  constructor(
    private _FormBuilder: FormBuilder,
    private _PlanService: PlanService,
    private _SharedDataService: SharedDataService,
    private _LoaderService: LoaderService,
    private _NotifierService: NotifierService,
    private _SessionService: SessionService,
    private _SubscriptionService: SubscriptionService,
    private _ConfirmationDialogService: ConfirmationDialogService,
    private _Router: Router,
    private _StripePaymentService: StripePaymentService,
    private _CardService: CardService,
    private _addDriverProcessService: AddDriverProcessServiceTsService
  ) {}

  ngOnInit(): void {
    this.form = this._FormBuilder.group({
      planId: [, [Validators.required]],
    });
    this._LoaderService.setState(true);
    this._SharedDataService.setHeaderTitle('Subscription');
    this.corporateData = JSON.parse(
      this._SessionService.getSession('userData') ?? ''
    );
    this.getPlanSubscriptionData();

  }
  ngDoCheck() {
    this.initializeTooltipData();
  }

  initializeTooltipData(){
    if(this.subscriptionDetails){
      this.tooltipDataMap = [
        {
          startDate: this.subscriptionDetails.start_date,
          endDate: this.subscriptionDetails.end_date,
        },
        {
          startDate: this.subscriptionDetails?.upcoming_subscription?.start_date,
          endDate: this.subscriptionDetails?.upcoming_subscription?.end_date,
        },
      ]
    }
  }


  getPlanSubscriptionData() {
    this._LoaderService.setState(true);
    this._PlanService.planList().subscribe({
      next: (response: Partial<IResponse>) => {
        if (response.success) {
          this.planList = response.data as IPlans[];
          this._SubscriptionService.getSubscriptionDetails().subscribe({
            next: (response: IResponse) => {
              if (response.success) {
                this.subscriptionDetails = response.data as ISubcriptionDetails;
                this.form.setValue({
                  planId: this.subscriptionDetails.plan_id,
                });
                this.setPlanList();
              }
            },
            error: (error: IError) => {
              this._LoaderService.setState(false);
              console.log('Inside get syubscription api error ', error);
            },
          });
        }
      },
      error: (error: Partial<IError>) => {
        this._LoaderService.setState(false);
        console.log('Inside plan list api error', error);
      },
    });
  }

  setDisabledClass(plan: IPlans): boolean {
    if (
      (this.subscriptionDetails.subscription_mode === payment_mode.ONLINE &&
        plan.duration === plan_duration.CUSTOM) ||
      (this.subscriptionDetails.subscription_mode === payment_mode.OFFLINE &&
        plan.duration !== plan_duration.CUSTOM)
    ) {
      return true;
    }
    return false;
  }

  isPlanActive(): boolean {
    return (
      this.subscriptionDetails.subscription_status !==
        subscription_status.CANCELLED &&
      this.subscriptionDetails.subscription_status !==
        subscription_status.EXPIRED
    );
  }

  disableCancleButton(): boolean {
    if (!this.subscriptionDetails.cancel_at_period_end) {
      if (this.subscriptionDetails.subscription_mode === payment_mode.ONLINE) {
        if (this.isPlanActive()) {
          return (
            this.activeUpcomingPlanList.findIndex(
              (plan) => plan.id === parseInt(this.form.get('planId')?.value)
            ) == -1
            // ||
            // parseInt(this.form.get('planId')?.value) ===
            //   this.subscriptionDetails.upcoming_subscription?.plan_id
          );
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  disableChangePlanButton(): boolean {
    if (this.subscriptionDetails.subscription_mode === payment_mode.ONLINE) {
      if (this.isPlanActive()) {
        return (
          this.activeUpcomingPlanList.findIndex(
            (plan) => plan.id === parseInt(this.form.get('planId')?.value)
          ) !== -1
        );
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setPlanList() {
    // For online
    if (this.subscriptionDetails.subscription_mode === payment_mode.ONLINE) {
      if (
        // subscription is 'active' or in 'trial' period
        this.subscriptionDetails.subscription_status !==
          subscription_status.CANCELLED &&
        this.subscriptionDetails.subscription_status !==
          subscription_status.EXPIRED
      ) {
        this.activeUpcomingPlanList = this.planList.filter(
          (plan: IPlans) =>
            plan.id == this.subscriptionDetails.plan_id &&
            this.subscriptionDetails.subscription_status !==
              subscription_status.CANCELLED &&
            this.subscriptionDetails.subscription_status !==
              subscription_status.EXPIRED
        );
        if (this.subscriptionDetails.upcoming_subscription) {
          // Check upcoming subscription found set it to activeUpcoming array
          this.activeUpcomingPlanList.push(
            ...this.planList.filter(
              (plan: IPlans) =>
                plan.id ===
                this.subscriptionDetails.upcoming_subscription?.plan_id
            )
          );
          this.otherPlanList = [
            {
              duration: 'custom',
            },
          ];
        } else {
          // Set remining plan to other plan list
          let otherPlan = this.planList.filter(
            (plan: IPlans) =>
              plan.id !== this.subscriptionDetails.plan_id &&
              plan.id !==
                this.subscriptionDetails?.upcoming_subscription?.plan_id
          );
          this.otherPlanList.unshift(...otherPlan);
        }
      } else {
        // subscription is 'cancelled' or 'expired'
        this.activeUpcomingPlanList = this.planList;
        this.subscriptionDetails = {
          ...this.subscriptionDetails,
          upcoming_subscription: null,
          plan_id: 0,
          duration: '',
        };
      }
    } else {
      // offline subscription
      this.activeUpcomingPlanList = [...this.otherPlanList];
      this.otherPlanList = this.planList;
    }
    this._LoaderService.setState(false);
  }

  changeSubscription() {
    let message = '';
    const changeSubscriptionPayload: IChangeSubscriptionPayload = {
      ...this.form.value,
    };
    if (
      this.subscriptionDetails.subscription_status ===
        subscription_status.CANCELLED ||
      this.subscriptionDetails.subscription_status ===
        subscription_status.EXPIRED
    ) {
      //add-subscription
      this._LoaderService.setState(true);
      this._SubscriptionService
        .addSubscription(changeSubscriptionPayload)
        .subscribe({
          next: (response: any) => {
            if (response?.data?.clientSecret) {
              this._LoaderService.setState(false);
              this.driverPaymentIntentData = response?.data; // store payment intent data
              this.showPaymentDetailsDriverCalculations(response?.data);
            } else {
              this._LoaderService.setState(true);
              setTimeout(() => {
                this.activeUpcomingPlanList = [];
                this.otherPlanList = [
                  {
                    duration: 'custom',
                  },
                ];
                this.getPlanSubscriptionData();
                this._NotifierService.showSuccess('Subscription Updated Successfully.')
                this._Router.navigate(['/corporate/']);
              }, 2000);
            }
          },
          error: (error: IError) => {
            this._NotifierService.showError(error.error?.message);
            console.log('Inside error======', error);
          },
        });
    } else if (
      this.subscriptionDetails.subscription_status ===
        subscription_status.ACTIVE &&
      !this.subscriptionDetails?.cancel_at_period_end
    ) {
      if (
        parseInt(this.form.controls['planId'].value) ==
        this.subscriptionDetails.upcoming_subscription?.plan_id
      ) {
        message = `You already have ${this.subscriptionDetails?.upcoming_subscription?.duration} plan selected as your upcoming plan. Please choose another plan`;
        this._ConfirmationDialogService.openInformativeDialog(
          'Subscription Cancelled',
          message,
          'informative'
        );
        return;
      }
      //change-subscription
      if (this.subscriptionDetails.duration === plan_duration.ANNUAL) {
        // switching from annually to monthly
        message =
          'You already have an annual account that is paid for until ' +
          formatDate(
            this.subscriptionDetails.end_date,
            'dd MMM yyyy',
            'en-US'
          ) +
          '. Are you sure you want to change to your billing cycle?';
      } else {
        // switching from monthly to annually
        message =
          'Your account will be upgraded to an annual subscription effective today.';
      }
      this._ConfirmationDialogService
        .openConfirmationDialog('Change Subscription', message, 'confirmation')
        .subscribe((result) => {
          if (result) {
            console.log('Activation Changed');
            this.changeSubscriptionAPICall(changeSubscriptionPayload);
          }
        });
    } else {
      this.changeSubscriptionAPICall(changeSubscriptionPayload);
    }
  }

  showPaymentDetailsDriverCalculations(driverData: PaymentIntent) {
    this._addDriverProcessService.setDriverCalculationData(driverData);
    // this.driverData = driverData //for sending data to the card list modal
    this._ConfirmationDialogService
      .openDriverCalculationsDialog(
        `DELM8 - ${driverData.planDuration}`,
        'driverCalculations',
        false
      )
      .subscribe((result) => {
        if (result) {
          this.openCardListModal(
            driverData
          );
        } else {
          this._LoaderService.setState(true);
          this._Router.navigate(['/corporate/']);
        }
      });
  }

  openCardListModal(driverData: PaymentIntent) {
    this._CardService.cardList().subscribe({
      next: (response: ICardsResponse) => {
        if (response.success) {
          this.cardList = response?.data;
          this._LoaderService.setState(false);
          //driver amount calculations
          const driverAmountSummary = {
            amount: driverData ? driverData.subTotal : '0',
            vat: driverData ? driverData.vatAmount : '0',
            totalAmount: driverData ? driverData.amount : '0',
          };
          this._ConfirmationDialogService
            .openCardListDialog('Select card', 'selectCard', this.cardList, driverAmountSummary, true)
            .subscribe((result: any) => {
              if (result) {
                this.handleOpenModal(result, driverData?.clientSecret, driverData?.transactionId);
              } else {
                this._LoaderService.setState(false);
              }
            });
        }
      },
      error: (error: any) => {
        this._NotifierService.showError(error.message);
      },
    });
  }

  handleOpenModal(
    optionData: any,
    clientSecret: string,
    transactionId: string
  ) {
    switch (optionData.option) {
      case 'addNewCard': {
        this._ConfirmationDialogService
          .openPayNowDialog(
            'Add Card Details',
            'cardDetails',
            clientSecret,
            transactionId,
            true,
            false
          )
          .subscribe((result) => {
            this._LoaderService.setState(true);
            if (result) {
              setTimeout(() => {
                this.activeUpcomingPlanList = [];
                this.otherPlanList = [
                  {
                    duration: 'custom',
                  },
                ];
                this.getPlanSubscriptionData();
                this._NotifierService.showSuccess('Subscription Updated Successfully.')
                this._Router.navigate(['/corporate/']);
              }, 2000);
            } else {
              this._LoaderService.setState(false);
            }
          });
        break;
      }
      case 'payNow': {
        this._StripePaymentService
          .confirmPaymentByToken(clientSecret, optionData?.selectedCard?.id)
          .subscribe((paymentResult: any) => {
            this._LoaderService.setState(true);
            if (paymentResult) {
              setTimeout(() => {
                this.activeUpcomingPlanList = [];
                this.otherPlanList = [
                  {
                    duration: 'custom',
                  },
                ];
                this.getPlanSubscriptionData();
                this._LoaderService.setState(false);
                this._NotifierService.showSuccess('Subscription Updated Successfully.')
              }, 2000);
            } else {
              this._LoaderService.setState(false);
              this.openCardListModal(this.driverPaymentIntentData);
            }
            this._SessionService.removeSessionStorage('driverData');
          });
        break;
      }
      default: {
        console.log('Default case triggered'); // Added log statement for debugging
        break;
      }
    }
  }

  changeSubscriptionAPICall(data: IChangeSubscriptionPayload) {
    this._LoaderService.setState(true);
    this._SubscriptionService.changeOnlineSubscription(data).subscribe({
      next: (response: any) => {
        if (response.success) {
          if (response?.data?.clientSecret) {
            this._LoaderService.setState(false);
            this.driverPaymentIntentData = response?.data; // store payment intent data
            // To show payment details of driver
            this.showPaymentDetailsDriverCalculations(response?.data);
            // this.openCardListModal(
            //   response?.data?.clientSecret,
            //   response?.data?.transactionId
            // );
          } else if (response.success) {
            setTimeout(() => {
              this._NotifierService.showSuccess(
                response?.message || 'Subscribed Successfully'
              );
              this.activeUpcomingPlanList = [];
              this.otherPlanList = [
                {
                  duration: 'custom',
                },
              ];
              this.getPlanSubscriptionData();
              this._LoaderService.setState(false);
              this._NotifierService.showSuccess('Subscription Updated Successfully.')
            }, 4000);
          }
        }
      },
      error: (error: Partial<IError>) => {
        this._NotifierService.showError(error.error?.message);
        console.log('Inside error======', error);
      },
    });
  }

  cancelSubscriptionModal() {
    let message =
      'Are you sure you want to cancel this subscription?';
    if (
      parseInt(this.form.get('planId')?.value) ===
      this.subscriptionDetails?.plan_id
    ) {
      if (this.subscriptionDetails.cancel_at_period_end) {
        message = `Your subscription has already been cancelled, will continue till ${formatDate(
          this.subscriptionDetails.end_date,
          'dd MMM yyyy',
          'en-US'
        )} day-s.`;
        this._ConfirmationDialogService.openInformativeDialog(
          'Subscription Cancelled',
          message,
          'informative'
        );
      } else {
        this._ConfirmationDialogService
          .openConfirmationDialog(
            'Cancel Subscription',
            message,
            'confirmation'
          )
          .subscribe((result) => {
            if (result) {
              console.log('Activation cancelled');
              this.cancelOrgSubscriptionAPICall();
            }
          });
      }
    } else if (
      parseInt(this.form.get('planId')?.value) ===
      this.subscriptionDetails?.upcoming_subscription?.plan_id
    ) {
      let message =
        'You are about to cancel your upcoming subscription. This action will cancel it, and your current subscription will continue on a recurring basis. Are you sure you want to proceed?';
      this._ConfirmationDialogService
        .openConfirmationDialog(
          'Cancel upcoming Subscription',
          message,
          'confirmation'
        )
        .subscribe((result) => {
          if (result) {
            console.log('Activation cancelled');
            this.cancelOrgSubscriptionAPICall();
          }
        });
    } else if (
      this.subscriptionDetails.subscription_mode === payment_mode.OFFLINE
    ) {
      let message =
        'You are about to cancel your subscription. If you cancel today, your subscription will continue to the end of the cycle. Are you sure you want to proceed?';
      this._ConfirmationDialogService
        .openConfirmationDialog(
          'Cancel custom Subscription',
          message,
          'confirmation'
        )
        .subscribe((result) => {
          if (result) {
            this.cancelOrgSubscriptionAPICall();
          }
        });

    }
  }

  cancelOrgSubscriptionAPICall() {
    this._LoaderService.setState(true);
    if (
      parseInt(this.form.get('planId')?.value) ===
      this.subscriptionDetails?.plan_id
    ) {
      this._SubscriptionService.cancelSubscription().subscribe({
        next: (response: IResponse) => {
          if (response.success) {
            this._NotifierService.showSuccess(response.message);
          }
          setTimeout(() => {
            this.activeUpcomingPlanList = [];
            this.otherPlanList = [
              {
                duration: 'custom',
              },
            ];
            this.getPlanSubscriptionData();
            this._LoaderService.setState(false);
            this._NotifierService.showSuccess('Subscription canceled Successfully.')
          }, 1000);
        },
        error: (error: Partial<IError>) => {
          this._LoaderService.setState(false);
          this._NotifierService.showError(error.error?.message);
          console.log('Inside error======', error);
        },
      });
    } else if (
      parseInt(this.form.get('planId')?.value) ===
      this.subscriptionDetails?.upcoming_subscription?.plan_id
    ) {
      this._SubscriptionService.cancelUpcomingOnlineSubscription().subscribe({
        next: (response: IResponse) => {
          if (response.success) {
            this._NotifierService.showSuccess(response.message);
          }
          setTimeout(() => {
            this.activeUpcomingPlanList = [];
            this.otherPlanList = [
              {
                duration: 'custom',
              },
            ];
            this.getPlanSubscriptionData();
            this._LoaderService.setState(false);
            this._NotifierService.showSuccess('Subscription canceled Successfully.')
          }, 1000);
        },
        error: (error: Partial<IError>) => {
          this._LoaderService.setState(false);
          this._NotifierService.showError(error.error?.message);
          console.log('Inside error======', error);
        },
      });
    } else if (
      this.subscriptionDetails.subscription_mode === payment_mode.OFFLINE
    ) {
      this._SubscriptionService.cancelSubscription().subscribe({
        next: (response: IResponse) => {
          if (response.success) {
            this._NotifierService.showSuccess(response.message);
          }
          setTimeout(() => {
            this.activeUpcomingPlanList = [];
            this.otherPlanList = [
              {
                duration: 'custom',
              },
            ];
            this.getPlanSubscriptionData();
            this._LoaderService.setState(false);
            this._NotifierService.showSuccess('Subscription Canceled Successfully.')
          }, 1000);
        },
      });
    }
  }
}
