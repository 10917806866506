import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import * as moment from 'moment';
import { StripeCardNumberComponent } from 'ngx-stripe';
import { Subject, takeUntil } from 'rxjs';
import { IError } from 'src/app/_types/common.types';
import {
  FieldMapping,
  IDriverStatusPayload,
  RequiredFields,
  ValidationRules,
  bulkUploadSampleArray,
} from 'src/app/_types/driver.types';
import { IPlans } from 'src/app/_types/plan.types';
import { ROLES, payment_mode, vatPercent } from 'src/app/constant/db.constant';
import { DriverService } from 'src/app/services/driver.service';
import { OrganizationService } from 'src/app/services/organization.service';
import {
  matchPasswordValidator,
  oldPswdMatchValidator,
} from 'src/app/validators/match_password';
import {
  formNoWhitespaceValidator,
  patterns,
} from 'src/app/validators/pattern_validation';
import { AddDriverProcessServiceTsService } from '../../services/add-driver-process.service';
import {
  CsvToJsonConvertorService,
  ExcelProcessingResult,
} from '../../services/csv-to-json-convertor.service';
import { FormService } from '../../services/form.service';
import { LoaderService } from '../../services/loader.service';
import { NotifierService } from '../../services/notifier.service';
import { SessionService } from '../../services/session.service';
import { StripePaymentService } from '../../services/stripe.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss', './_dialog.scss'],
})
export class ConfirmationDialogComponent {
  changePassword: FormGroup;
  editPlan: FormGroup;
  subForm!: FormGroup;
  previousSubForm!: FormGroup;
  driverDataCalculations: any =
    this._addDriverProcessService.driverDataCalculations;
  cardNumberValid: boolean = false;
  cardExpiryValid: boolean = false;
  cardCvcValid: boolean = false;
  planList: IPlans[] = [];
  planData: string = '';
  todayDate = new Date();
  driverSubTotal: any;
  vatPercent: any;
  totalAmount: any;
  end_date: any;
  selectedOrganisationPaymentMode: boolean = false;
  private unsubscribe$ = new Subject<void>();
  planForm!: FormGroup;
  paymentForm: FormGroup;
  @ViewChild(StripeCardNumberComponent)
  card!: StripeCardNumberComponent;
  selectedCard: any;
  userRole: ROLES | null = null;
  isCardSaved: boolean = false;
  bulkUploadSuccessfull: boolean = false;

  // bulk upload
  csvErrorMessage: string = '';
  csvLimitExceed: boolean = false;
  fileTypeCsv: boolean = false;
  bulkDriversList: ExcelProcessingResult | null = null;
  @ViewChild('bulkUploadFile')
  bulkUploadFile!: ElementRef<HTMLInputElement>;
  showBulkUpoadTooltip: boolean = false;
  showDownloadReportBtn: boolean = false;
  showIgnoreAndDwnldTooltip: boolean = false;
  showIgnoreUploadButton: boolean = true;
  fileName: string = '';
  selectCard(card: any) {
    this.selectedCard = card;
  }

  recordsList = [
    {
      firstName: 'Kenna',
      lastName: 'Decker',
      email: 'kdecker@xyz.com',
      phone: '+44 1245 1245 66',
      errorDetail: 'Please enter a valid telephone number.',
    },
  ];

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#red31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#CFD7E0',
        },
        '::selection': {
          backgroundColor: '#DA3C3D',
          color: '#F6D8DA',
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRefLocal: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public corporateData: any,

    private _FormBuilder: FormBuilder,
    private _FormService: FormService,
    private _LoaderService: LoaderService,
    private _Router: Router,
    private _SessionService: SessionService,
    private _NotifierService: NotifierService,
    private _addDriverProcessService: AddDriverProcessServiceTsService,
    private _OrganizationService: OrganizationService,
    private _StripePaymentService: StripePaymentService,
    private _HtttpClient: HttpClient,
    private _CsvToJsonConvertorService: CsvToJsonConvertorService,
    private _DriverService: DriverService,
    private _AddDriverProcessServiceTsService: AddDriverProcessServiceTsService,
    private _ConfirmationDialogService: ConfirmationDialogService
  ) {
    dialogRef.disableClose = true;
    // for change password
    this.changePassword = this._FormBuilder.group(
      {
        oldPassword: [''],
        newPassword: [
          '',
          [Validators.required, , oldPswdMatchValidator('oldPassword')],
        ],
        confirmPassword: [
          '',
          [Validators.required, matchPasswordValidator('newPassword')],
        ],
      },
      { validators: [formNoWhitespaceValidator] } as AbstractControlOptions
    );
    this.subscribeToPasswordChanges();

    //for payment
    this.paymentForm = this._FormBuilder.group(
      {
        name_on_card: [
          '',
          [
            Validators.required,
            Validators.pattern(patterns.NAME_ON_CARD.regex),
          ],
        ],
      },
      { validators: [formNoWhitespaceValidator] } as AbstractControlOptions
    );

    //for edit plan - admin
    this.editPlan = this._FormBuilder.group(
      {
        plan_name: [
          '',
          [Validators.required, Validators.pattern(patterns.PLAN_NAME.regex)],
        ],
      },
      { validators: [formNoWhitespaceValidator] } as AbstractControlOptions
    );
    //for update subscription
    this.subForm = this._FormBuilder.group(
      {
        start_date: [{ value: this.todayDate, disabled: true }],
        end_date: ['', [Validators.required]],
        number_of_drivers: [
          '',
          [
            Validators.pattern(patterns.NUMBER_OF_DRIVERS.regex),
            Validators.required,
          ],
        ],
        cost_per_driver: [
          '',
          [
            Validators.pattern(patterns.COST_PER_DRIVER.regex),
            Validators.required,
          ],
        ],
      },
      { validators: [formNoWhitespaceValidator] } as AbstractControlOptions
    );

    //for fetching previous subscription data
    this.previousSubForm = this._FormBuilder.group({
      startDate: [''],
      endDate: [''],
      numberOfDrivers: [''],
      cost_per_driver: [''],
    });
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onClose(result: boolean): void {
    if (result) {
      if (this.changePassword.valid) {
        //as confirmPswd is not allowed
        const data = {
          ...this.changePassword.value,
        };
        delete data.confirmPassword;
        this.dialogRef.close(data);
      } else {
        this.dialogRef.close(result);
      }
    } else {
      this._LoaderService.setState(false);
      this.dialogRef.close(result);
    }
  }

  changePswd(isFirstTimeLogin: boolean) {
    this._LoaderService.setState(true);
    let data: any;
    if (this.changePassword.valid) {
      //as confirmPswd is not allowed
      data = isFirstTimeLogin
        ? { newPassword: this.changePassword?.get('newPassword')?.value }
        : { ...this.changePassword.value };
      delete data.confirmPassword;
      this._OrganizationService
        .changePassword(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response: any) => {
            if (response.success) {
              if (isFirstTimeLogin) {
                // this._AuthService.logout();
                this._SessionService.setSession({
                  data: JSON.stringify({
                    ...this.data.userData,
                    password_change_required: false,
                  }),
                });
                this._NotifierService.showSuccess(response.message);
                this._Router.navigate(['/corporate/dashboard']);
                this._LoaderService.setState(false);
                this.dialogRef.close(true);
              } else {
                this._NotifierService.showSuccess(response.message);
                this._LoaderService.setState(false);
                if (this.userRole === ROLES.CORPORATOR) {
                  this._Router.navigate(['/corporate/profile']);
                } else if (this.userRole === ROLES.ROLE_SUPER_ADMIN) {
                  this._Router.navigate(['/admin/profile']);
                }
                this.dialogRef.close(true);
              }
            }
          },
          error: (error: IError) => {
            this._NotifierService.showError(
              error.error ? error.error.message : ''
            );
          },
        });
    }
  }

  editPlanDetails(result: boolean, data: string) {
    if (result) {
      this.dialogRef.close(data);
    }
  }

  ngOnInit(): void {
    this.planForm = this._FormBuilder.group({
      planId: ['', [Validators.required]],
    });
    this.corporateData ? this.fetchOrganisationDetailsById() : '';
    //managed only for edit plan rightnow
    this.data ? this.fetchPlanDetails() : '';
    this.userRole = this._SessionService.getSession('user_role') as ROLES;

    //adding old pswd validation for changePswd modal
    if (!this.data.isFirstTimeLogin) {
      this.changePassword
        .get('oldPassword')
        ?.setValidators(Validators.required);
      this.changePassword.get('oldPassword')?.updateValueAndValidity();
    }
    if (this.data.cardList) {
      const defaultCard = this.data.cardList.find(
        (card: { isDefault: boolean }) => card.isDefault
      );
      if (defaultCard) {
        this.selectedCard = defaultCard;
      }
    }
  }

  getErrorMessage(
    controlName: string,
    formType:
      | 'changePassword'
      | 'cardDetails'
      | 'editPlan'
      | 'subscriptionEditModal'
  ): string {
    let form;
    switch (formType) {
      case 'changePassword':
        form = this.changePassword;
        break;
      case 'cardDetails':
        form = this.paymentForm;
        break;
      case 'editPlan':
        form = this.editPlan;
        break;
      case 'subscriptionEditModal':
        form = this.subForm;
        break;
    }
    return this._FormService.getErrorMessage(controlName, form);
  }

  /**
   * *This model opens when user clicks on pay now in driver calculations modal.
   */
  payNow() {
    if (this.paymentForm.valid) {
      this._LoaderService.setState(true);
      if (this.data?.eventName === 'payNow') {
        this._StripePaymentService
          .createToken(
            this.driverDataCalculations?.clientSecret ??
              this.data?.clientSecret,
            this.card.element,
            this.paymentForm.get('name_on_card')?.value,
            this.data?.transactionId,
            this.isCardSaved
          )
          .subscribe((paymentResult: any) => {
            this._SessionService.removeSessionStorage('driverData');
            if (paymentResult) {
              this.dialogRef.close(paymentResult);
            } else {
              this._LoaderService.setState(false);
            }
          });
      } else if (this.data?.eventName === 'addCard') {
        this._StripePaymentService
          .createCardOnStripe(
            this.card.element,
            this.paymentForm.get('name_on_card')?.value
          )
          .subscribe((paymentResult: any) => {
            if (paymentResult) {
              this.dialogRef.close(paymentResult);
            } else {
              this._LoaderService.setState(false);
            }
          });
      }
    }
  }

  /**
   * *This method is getting used for both for closing the modal as well as for clearing session storage of drivers.
   */
  payLaterOrOnClose() {
    if (this.data?.eventName === 'addCard') {
      this._ConfirmationDialogService
        .openConfirmationDialog(
          'Cancel Process',
          'Are you sure you want to cancel this process?',
          'confirmation'
        )
        .subscribe((result) => {
          if (result) {
            this._SessionService.removeSessionStorage('driverData');
            this.dialogRef.close(false);
          }
        });
    } else {
      this._SessionService.removeSessionStorage('driverData');
      this.dialogRef.close(false);
    }
    // this._Router.navigate(['/corporate/dashboard']);
  }
  fetchOrganisationDetailsById() {
    const modalData = this.corporateData;
    const corporateData = modalData.data;
    if (modalData.payment_mode === payment_mode.OFFLINE) {
      this.selectedOrganisationPaymentMode = true;
      this.previousSubForm.patchValue({
        startDate: moment(corporateData?.subscriptions?.start_date).format(
          'DD/MM/YYYY'
        ),
        endDate: moment(corporateData?.subscriptions?.end_date).format(
          'DD/MM/YYYY'
        ),
        numberOfDrivers: corporateData?.number_of_drivers,
        cost_per_driver: corporateData?.subscriptions?.cost_per_driver,
      });
      this.previousSubForm.disable();
    }
  }

  //for fetching plan details while edit plan - admin
  fetchPlanDetails() {
    this.editPlan.patchValue({
      plan_name: this.data?.data?.plan_name,
    });
  }

  updateSubscription(result: boolean, data: any) {
    if (result) {
      this.dialogRef.close(data);
    }
  }
  totalAmountCalculation() {
    this.driverSubTotal =
      this.subForm.get('cost_per_driver')?.value *
      this.subForm.get('number_of_drivers')?.value;
    this.vatPercent = this.driverSubTotal * vatPercent.VATPERCENT;
    this.totalAmount = this.driverSubTotal + this.vatPercent;
  }

  onChange(ev: any, elementType: string) {
    let displayError: HTMLElement | null;
    switch (elementType) {
      case 'cardNumber':
        displayError = document.getElementById('card-number-errors');
        !ev?.empty && ev?.empty !== undefined
          ? (this.cardNumberValid = true)
          : (this.cardNumberValid = false);

        ev?.complete && ev?.complete !== undefined
          ? (this.cardNumberValid = true)
          : (this.cardNumberValid = false);
        break;
      case 'cardExpiry':
        displayError = document.getElementById('card-expiry-errors');
        !ev?.empty && ev?.empty !== undefined
          ? (this.cardExpiryValid = true)
          : (this.cardExpiryValid = false);

        ev?.complete && ev?.complete !== undefined
          ? (this.cardExpiryValid = true)
          : (this.cardExpiryValid = false);
        break;

      case 'cardCvc':
        displayError = document.getElementById('card-cvc-errors');
        !ev?.empty && ev?.empty !== undefined
          ? (this.cardCvcValid = true)
          : (this.cardCvcValid = false);

        ev?.complete && ev?.complete !== undefined
          ? (this.cardCvcValid = true)
          : (this.cardCvcValid = false);

        break;

      default:
        displayError = null;
        break;
    }
    if (ev?.empty && displayError) {
      displayError.textContent =
        'Enter ' +
        ev.elementType.replace(/([a-z])([A-Z])/g, '$1 $2').toLocaleLowerCase();
    } else if (!ev?.complete && displayError) {
      displayError.textContent = ev.error?.message;
    } else {
      displayError!.innerHTML = '';
    }
  }

  /**
   * *Method to make API call when user is expired or cancelled and selects new subscription from add new subscription dialog.
   */
  addNewSubscription() {
    this._LoaderService.setState(true);
    if (this.planForm.valid) {
      const storage = this._SessionService.getSessionStorage('driverData');

      if (this.data.driver_status) {
        let driverStarusPayload: IDriverStatusPayload = {
          driverId: this.data?.id,
          status: this.data?.driver_status,
          updatedPlanDuration: this.planForm.get('planId')?.value,
        };
        this.dialogRef.close(driverStarusPayload);
      } else {
        let storageArray = JSON.parse(storage!);
        const addDriverPayload = {
          drivers: storageArray,
          updatedPlanDuration: this.planForm.get('planId')?.value,
        };
        this.dialogRef.close(addDriverPayload);
      }
    }
  }

  /**
   * *Subscribe to password changes.
   */
  private subscribeToPasswordChanges(): void {
    this.changePassword.get('newPassword')?.valueChanges.subscribe(() => {
      this.changePassword.get('confirmPassword')?.updateValueAndValidity();
    });

    this.changePassword.get('oldPassword')?.valueChanges.subscribe(() => {
      this.changePassword.get('newPassword')?.updateValueAndValidity();
    });
  }

  openLinkInNewPage() {
    this.dialogRef.close();
    const url = 'https://delm8.com/contact-us/';
    window.open(url, '_blank');
  }
  // ngOnDestroy() {
  //   this._SessionService.removeSessionStorage('driverData');
  // }

  handleDriverCalculationButtonClick(cancel: boolean) {
    this._SessionService.removeSessionStorage('driverData');
    this.dialogRef.close(cancel);
  }

  handleCardButtonClick(event: string) {
    this._SessionService.removeSessionStorage('driverData');
    if (event === 'add') {
      this.dialogRef.close({ option: 'addNewCard' });
    } else {
      this.dialogRef.close({
        option: 'payNow',
        selectedCard: this.selectedCard,
      });
    }
  }

  handleSubscriptionCancel() {
    this.dialogRef.close(false);
  }

  // Start bulk upload
  async onFileChange(event: any) {
    this.csvErrorMessage = '';
    this.bulkDriversList = null;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let fileNameWithPathArray = this.bulkUploadFile.nativeElement.value.split('\\');
      this.fileName = fileNameWithPathArray[fileNameWithPathArray.length - 1];
      const fileExtension: string = file.type;

      if (
        fileExtension !== 'text/csv' &&
        fileExtension !== 'application/vnd.ms-excel'
      ) {
        this.fileTypeCsv = false;
        this.csvErrorMessage = 'Please upload a .csv file.';
        // this.cancelBulkUpload(true);
        return;
      }
      try {
        this.bulkDriversList =
          await this._CsvToJsonConvertorService.processExcelFile(
            file,
            FieldMapping,
            [...RequiredFields, ...ValidationRules]
          );
        if (
          !this.bulkDriversList.invalidRows.length &&
          !this.bulkDriversList.validRows.length
        ) {
          this.csvErrorMessage = 'Please ensure that you have fill records.';
        } else if (!this.bulkDriversList.validRows.length) {
          this.csvErrorMessage =
            'All the records are invalid, please refer below table.';
        } else if (this.bulkDriversList.invalidRows.length) {
          this.csvErrorMessage =
            'Incorrect data format in the file. See below for details.';
        }
      } catch (error) {
        console.log('Error processing Excel file:', error, typeof error);
        if (typeof error === 'string') {
          this.csvErrorMessage = error;
          this.csvLimitExceed = true;
          // this.cancelBulkUpload(true);
        }
      }
    }
  }

  async onFileClick(event: any) {
    this.bulkUploadFile.nativeElement.value = '';
    this.showIgnoreUploadButton = true;
  }
  checkErrorInCsv(): boolean {
    // if (this.bulkDriversList?.validRows.length) {
    //   return this.bulkDriversList?.invalidRows.length ? true : false;
    // } else {
    //   return true;
    // }

    if (!this.bulkDriversList) {
      return true;
    } else if (!this.bulkDriversList?.validRows.length) {
      return true;
    } else if (this.csvLimitExceed) {
      return true;
    }
    return this.showDownloadReportBtn
      ? this.bulkDriversList.invalidRows.length > 0
      : false;
  }

  cancelBulkUpload(resetState: boolean = false) {
    // if (!resetState) this.bulkUploadModal = false;
    // this.bulkUploadFile.nativeElement.value = '';
    this.bulkDriversList = null;
    this.csvErrorMessage = '';
    this.csvLimitExceed = false;
    this.fileTypeCsv = false;
  }

  uploadBulkDriver() {
    this._LoaderService.setState(true);
    const addDriverPayload = {
      drivers: this.bulkDriversList?.validRows,
    };
    this._DriverService
      .uploadBulkDriver(addDriverPayload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: any) => {
          this.showIgnoreUploadButton = false;
          if (response.success) {
            this._AddDriverProcessServiceTsService.emitValueChanged();
            if (response.data) {
              this._NotifierService.showSuccess(response.message);
              this._LoaderService.setState(false);
              this.csvErrorMessage = `Data load failed.<br />SOME or all of your records have not been loaded. Please review the error report and try again. <br /> <span> Note: You only need to re-upload the records in this error report.</span>`;
              // Map response.data to match bulkDriversList.invalidRows structure
              if (this.bulkDriversList) {
                this.bulkDriversList.invalidRows = response.data.map(
                  (item: { [x: string]: string }) => ({
                    row: {
                      'First Name': item['First Name'],
                      'Last Name': item['Last Name'],
                      Email: item['Email'],
                      'Phone Number': item['Telephone Number'],
                    },
                    errors: [item['Error Message']],
                  })
                );
              }
              this.showDownloadReportBtn = true;
            } else {
              this.bulkUploadSuccessfull = true;
              this.cancelBulkUpload();
              this._NotifierService.showSuccess(response.message);
              this._LoaderService.setState(false);
              // this.bulkUploadFile.nativeElement.value = '';
              this.bulkDriversList = null;
            }
          }
        },
        error: (error) => {
          this.showIgnoreUploadButton = false;
          console.log('Error while adding driver in bulk', error);
          this.cancelBulkUpload();
          this._LoaderService.setState(false);
          // this._NotifierService.showError(error.error.message);
          this.csvErrorMessage = error.error.message;
        },
      });
  }
  // end bulk upload

  downloadErrorReport() {
    if (this.bulkDriversList) {
      // Convert array of objects to CSV content
      const csvContent = this.convertArrayOfErrorObjectsToCSV(
        this.bulkDriversList.invalidRows
      );

      // Create a Blob object for the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Create a link element, set the href attribute to point to the Blob, and trigger a click to initiate download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'driver-error-report.csv';
      link.click();
    }
  }

  downloadSampleCSVFile() {
    // Convert array of objects to CSV content
    const headers = Object.keys(bulkUploadSampleArray[0]).join(',');
    // Remove the first object from the array to use it for CSV data
    const sampleArray = bulkUploadSampleArray.slice(1);
    // Map over the remaining array to create CSV rows
    const csvRows = sampleArray.map((data) => {
      return Object.values(data).join(',');
    });
    // Combine headers and rows
    const csvContent = `${headers}\n${csvRows.join('\n')}`;
    // Create a Blob object for the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a link element, set the href attribute to point to the Blob, and trigger a click to initiate download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'delm8_corporate_drivers.csv';
    link.click();
  }

  convertArrayOfErrorObjectsToCSV(data: any[]): string {
    const headers = Object.keys(data[0].row).concat(['Errors']).join(',');

    // Convert each object to a CSV row
    const csvRows = data.map((item) => {
      const rowData = Object.values(item.row)
        .map((value) => `"${value}"`)
        .join(',');

      // Extract errors (if any)
      const errors =
        item.errors.length > 0 ? `"${item.errors.join(', ')}"` : '';

      return `${rowData},${errors}`;
    });

    // Combine headers and rows
    const csvContent = `${headers}\n${csvRows.join('\n')}`;
    return csvContent;
  }
}
