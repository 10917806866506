import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function matchPasswordValidator(matchControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const matchControl = control.parent?.get(matchControlName);

    if (matchControl && control.value !== matchControl.value) {
      return { mismatch: true };
    }

    return null;
  };
}
//checks if the new pswd matches the old pswd
export function oldPswdMatchValidator(matchControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const matchControl = control.parent?.get(matchControlName);

    if (matchControl && control.value == matchControl.value) {
      return { preExist: true };
    }

    return null;
  };
}
