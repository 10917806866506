
    export const environment = {
      production: false,
      featureFlag: false,
      apiBaseUrl: 'https://dev-api-corporate.delm8.com/api/',
      stripeKey:
      'pk_test_51NL5GVFJgdG6Gq1A3ru3nJ7epHd5tvo9nuQPtnCdBjFjGRaBGoNlRIhorSiNVqfstsBHzPSB6hsJBfkUdnKhyNEX00KMeeAJs6',
      recaptchaSiteKey:'6LdKvOspAAAAABUyg4dgytY80iyGXC9swY21E1oh',
      clientSecretKey:'$2b$10$lBLNTh.E8dHQ0ekzqpcbEO4LKo14cLCa2dfRpInOLIT.ezOnuDPyi'
    };
  