import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IError, IResponse } from '../_types/common.types';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  authAPIBaseUrl = `${environment.apiBaseUrl}plan/`;

  constructor(private http: HttpClient) {}
  planList(): Observable<IResponse | IError> {
    const url = this.authAPIBaseUrl + `list`;

    const headers = new HttpHeaders({
      'clientSecret': `${environment.clientSecretKey}`,
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(url, { headers: headers });
  }

  planEdit(data: any, id: number): Observable<IResponse | IError> {
    const url = apiConfig.plan + `/update?planId=${id}`;
    return this.http.put<any>(url, data);
}
}
