<div class="table-wrapper" *ngIf="dataSource">
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="SortableColumn"
      matSortDisableClear
      matSortDirection="desc"
    >
      <ng-container *ngFor="let tableField of tableFieldList">
        <!-- //case 1 ; for date filters columns -->
        <div
          *ngIf="DateColumns.includes(tableField.key)"
          [matColumnDef]="tableField.key"
        >
          <ng-container
            *ngIf="SortableColumn && SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              {{ tableField.header }}
            </th>
          </ng-container>
          <ng-container
            *ngIf="!SortableColumn || !SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef>
              {{ tableField.header }}
            </th>
          </ng-container>
          <td mat-cell *matCellDef="let element">
            {{
              (!ObjectColumnToRender.includes(tableField.key)
                ? element[tableField.key]
                : (element
                  | nestedObjectValue
                    : ObjectColumn[tableField.key]
                    : tableField.key == "name")
              ) | date : "dd MMM yyyy"
            }}
          </td>
        </div>

        <!-- //case 2 : for status chip element -->
        <div
          *ngIf="StatusInChipColumn.includes(tableField.key)"
          [matColumnDef]="tableField.key"
        >
          <ng-container
            *ngIf="SortableColumn && SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              {{ tableField.header }}
            </th>
          </ng-container>
          <ng-container
            *ngIf="!SortableColumn || !SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef>
              {{ tableField.header }}
            </th>
          </ng-container>
          <td mat-cell *matCellDef="let element">
            <mat-chip-option
              class="status"
              [ngClass]="{
                'status-green':
                  (!ObjectColumnToRender.includes(tableField.key)
                    ? element[tableField.key]
                    : (element
                      | nestedObjectValue : ObjectColumn[tableField.key])) ===
                  'active' || (!ObjectColumnToRender.includes(tableField.key)
                  ? element[tableField.key]
                  : (element
                    | nestedObjectValue : ObjectColumn[tableField.key])) ===
                'paid',

                'status-red':
                  (!ObjectColumnToRender.includes(tableField.key)
                    ? element[tableField.key]
                    : (element
                      | nestedObjectValue : ObjectColumn[tableField.key])) ===
                  'in_active' || (!ObjectColumnToRender.includes(tableField.key)
                  ? element[tableField.key]
                  : (element
                    | nestedObjectValue : ObjectColumn[tableField.key])) ===
                'failed' || (!ObjectColumnToRender.includes(tableField.key)
                ? element[tableField.key]
                : (element
                  | nestedObjectValue : ObjectColumn[tableField.key])) ===
              'Active Till End' || (!ObjectColumnToRender.includes(tableField.key)
              ? element[tableField.key]
              : (element
                | nestedObjectValue : ObjectColumn[tableField.key])) ===
            'In grace day',


                'status-orange':
                  (!ObjectColumnToRender.includes(tableField.key)
                    ? element[tableField.key]
                    : (element
                      | nestedObjectValue : ObjectColumn[tableField.key])) ===
                  'trial' || (!ObjectColumnToRender.includes(tableField.key)
                  ? element[tableField.key]
                  : (element
                    | nestedObjectValue : ObjectColumn[tableField.key])) ===
                'pending',

                'status-grey':
                  (!ObjectColumnToRender.includes(tableField.key)
                    ? element[tableField.key]
                    : (element
                      | nestedObjectValue : ObjectColumn[tableField.key])) ===
                  'cancelled',
              }"
            >
              {{
                (!ObjectColumnToRender.includes(tableField.key)
                  ? element[tableField.key]
                  : (element | nestedObjectValue : ObjectColumn[tableField.key])
                )
                  ?.split("_")
                  ?.join("") | titlecase
              }}
            </mat-chip-option>
          </td>
        </div>

        <!-- //case 3 : for actions -->
        <div *ngIf="tableField.key == 'action'" [matColumnDef]="tableField.key">
          <th mat-header-cell *matHeaderCellDef>
            {{ tableField.header }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-actions">
              <button
                mat-icon-button
                *ngIf="ShowActionIcons.includes('view')"
                (click)="handleView(element.id)"
              >
                <img src="assets/images/icons/view-icon.svg" />
              </button>
              <button
                mat-icon-button
                *ngIf="ShowActionIcons.includes('delete')"
                (click)="handleOptionClick(element, 'delete')"
              >
                <img src="assets/images/icons/delete-form.svg" alt="Delete" />
              </button>
              <button
                mat-icon-button
                (click)="getActionOptionsList(element)"
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
                *ngIf="ShowActionIcons.includes('other')"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="custom-dropdown">
                <button
                  mat-menu-item
                  *ngFor="let menu of ActionOptionsList | async"
                  (click)="handleOptionClick(element, menu.key)"
                >
                  <span>{{ menu.value }}</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </div>

        <!-- //case default : for default normal field-->
        <div
          *ngIf="
            !StatusInChipColumn.includes(tableField.key) &&
            !DateColumns.includes(tableField.key) &&
            tableField.key !== 'action'
          "
          [matColumnDef]="tableField.key"
        >
          <ng-container
            *ngIf="SortableColumn && SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              {{ tableField.header }}
            </th>
          </ng-container>
          <ng-container
            *ngIf="!SortableColumn || !SortableColumn.includes(tableField.key)"
          >
            <th mat-header-cell *matHeaderCellDef>
              {{ tableField.header }}
            </th>
          </ng-container>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                tableField.key == 'name' &&
                  !ObjectColumnToRender.includes(tableField.key);
                else otherField
              "
            >
              {{ element["first_name"] }} {{ element["last_name"] }}
            </ng-container>
            <ng-template #otherField>
              <div
                *ngIf="!tableField.isBooleanField; else booleanFieldColumn"
                class="icon-wrapper icon-cell"
              >
                <img
                  *ngIf="tableField.key === 'email'"
                  src="assets/images/icons/mail.svg"
                />
                <img
                  *ngIf="tableField.key === 'telephone_number'"
                  src="assets/images/icons/phone.svg"
                /><span *ngIf="tableField.key === 'telephone_number'"
                  >+44
                </span>
                <ng-container
                  *ngIf="tableField.key === 'plan_name'; else nonSubField"
                >
                  {{
                    !ObjectColumnToRender.includes(tableField.key)
                      ? element[tableField.key]
                      : (element
                          | nestedObjectValue
                            : ObjectColumn[tableField.key]
                            : tableField.key == "name") ?? "Custom"
                  }}
                </ng-container>
                <ng-template #nonSubField>
                  <ng-container
                    *ngIf="tableField.key === 'email'; else nonEmailField"
                  >
                    <span>
                      {{
                        !ObjectColumnToRender.includes(tableField.key)
                          ? element[tableField.key]
                          : (element
                              | nestedObjectValue
                                : ObjectColumn[tableField.key]
                                : tableField.key == "name") ?? "N/A"
                      }}
                    </span>
                  </ng-container>
                  <ng-template #nonEmailField>
                    <ng-container
                      *ngIf="
                        tableField.key === 'invoice_no';
                        else titleCaseFields
                      "
                    >
                      <span>
                        {{
                          !ObjectColumnToRender.includes(tableField.key)
                            ? element[tableField.key].toString()
                            : (element
                                | nestedObjectValue
                                  : ObjectColumn[tableField.key]
                                  : tableField.key == "name") ?? "N/A"
                        }}
                      </span>
                    </ng-container>
                    <ng-template #titleCaseFields>
                      <span>
                        {{
                          tableField.key === "amount"
                            ? "£" +
                              (!ObjectColumnToRender.includes(tableField.key)
                                ? element[tableField.key]
                                : (element
                                  | nestedObjectValue
                                    : ObjectColumn[tableField.key]
                                    : tableField.key == "name"
                                  | number : "1.2-2"))
                            : !ObjectColumnToRender.includes(tableField.key)
                            ? (element[tableField.key].toString())
                            : ((element
                                | nestedObjectValue
                                  : ObjectColumn[tableField.key]
                                  : tableField.key == "name") ?? "N/A"
                              )
                        }}
                      </span>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </div>
              <ng-template #booleanFieldColumn>
                <span> {{ element[tableField.key] ? "Yes" : "No" }}</span>
              </ng-template>
            </ng-template>
          </td>
        </div>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnKeys"
        class="custom-header"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnKeys"></tr>
      <tr class="mat-row no-data-message" *matNoDataRow>
        <td [attr.colspan]="displayedColumnKeys.length">No data Found</td>
      </tr>
    </table>
  </div>
  <!-- Pagination -->
  <mat-paginator
    #paginator
    appPaginator
    (page)="handlePageSizeChange($event)"
    [appCustomLength]="paginationObj.itemCount"
    [appCustomPageSize]="paginationObj.pageSize"
    [length]="paginationObj.itemCount"
    [pageSize]="paginationObj.pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="paginationObj.currentPage"
    aria-label="Select page"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
