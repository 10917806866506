<div *ngIf="control" class="form-group" [ngClass]="classes">
  <mat-form-field appearance="outline" [ngClass]="{'telephone-input-wrapper': isTelephone}">
    <mat-label *ngIf="label">{{ label }}</mat-label>
  <ng-container *ngIf="isTelephone; else otherFields">
    <span matPrefix class="prefix">+44</span>
    <div class="vr pipe"></div>
    <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control"
      [ngClass]="classes" (input)="onChange($event)" (blur)="onBlur()" class="telephone-input" maxlength="11"/>
  </ng-container>
    <ng-template #otherFields>
      <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control"
        [ngClass]="classes" (input)="onChange($event)" (blur)="onBlur()" [attr.maxlength]="maxlength"/>
      <div *ngIf="type === 'password'" (click)="togglePasswordVisibility()">
        <span *ngIf="!hidePassword" class="password-icon">
          <img src="assets/images/icons/ph-eye-open.svg" alt="Eye Slash" />
        </span>
        <span *ngIf="hidePassword" class="password-icon">
          <img src="assets/images/icons/ph--eye-closed.svg" alt="Eye Slash" />
        </span>
      </div>
    </ng-template>
    <img
      src="assets/images/icons/lucide_info.svg"
      alt="i-icon"
      class="info-icon-img"
      *ngIf="isTooltipVisible"
      matTooltip="{{ tooltipMessage }}"
      matTooltipClass="custom-tooltip"
    />

    <mat-error *ngIf="control.invalid && control.touched" class="error-message">
      {{ errorMessage }}
    </mat-error>
  </mat-form-field>
</div>
