// import { environment } from "src/environments/environment";
export enum default_status {
  ACTIVE = 'active',
  INACTIVE = 'in_active',
}

export enum ROLES {
  ROLE_SUPER_ADMIN = 'super_admin',
  CORPORATOR = 'corporator',
  ROLE_DRIVER = 'driver'
}

export enum orderBy {
  asc = 'ASC',
  desc = 'DESC',
}

// TODO: change once api developer confirm final enum
export enum payment_status {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export enum payment_mode {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum driver_status {
  ACTIVE = 'active',
  INACTIVE = 'in_active',
}

export enum plan_duration {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
  CUSTOM =  'custom',
}

export enum subscription_status {
  ACTIVE = 'active',
  EXPIRED = 'expired', // after subscription's end date
  CANCELLED = 'cancelled', // corporator or admin cancelled subscription in between
  IN_ACTIVE = 'in_active', // initial stage
  TRIAL = 'trial', // trial stage
}

export enum vatPercent {
  VATPERCENT = 0.2
}
