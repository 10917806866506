import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ICards } from 'src/app/_types/card.types';
import { IPlans } from 'src/app/_types/plan.types';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  // for confirmation messages
  openConfirmationDialog(
    title: string,
    message: string,
    type: string,
    confirmationType?: string,  // for delete driver
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      disableClose: true,
      data: { title, message, type, confirmationType },
    });
    return dialogRef.afterClosed();
  }

  // for change password
  openChangePswdDialog(title: string, type: string, isFirstTimeLogin: boolean = false, userData: any = null): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      height: 'auto',
      data: { title, type, isFirstTimeLogin, userData },
    });

    return dialogRef.afterClosed();
  }

  openDriverCalculationsDialog(
    title: string,
    type: string,
    showPayLater: boolean = true,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      height: 'auto',
      disableClose: true,
      data: { title, type, showPayLater },
    });
    return dialogRef.afterClosed();
  }

  openCardListDialog(
    title: string,
    type: string,
    cardList: ICards[] | null,
    driverAmountSummary: any,
    showCancelIcon: boolean = false,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '760px',
      height: 'auto',
      disableClose: true,
      data: { title, type, cardList, driverAmountSummary, showCancelIcon },
    });
    return dialogRef.afterClosed();
  }

  openPayNowDialog(title: string, type: string, clientSecret: string = '', transactionId: string = '', isCardSaved: boolean = true, showPayLater: boolean = true, eventName: string = 'payNow'): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      height: 'auto',
      disableClose: true,
      data: { title, type, clientSecret, transactionId, isCardSaved, showPayLater, eventName },
    });
    return dialogRef.afterClosed();
  }

  openDriverExceedMessageDialog(
    title: string,
    message: string,
    type: string
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      disableClose: true,
      data: { title, message, type },
    });

    return dialogRef.afterClosed();
  }

  openAddNewSubscriptionDialog(
    title: string,
    type: string,
    planList: IPlans[],
    driver_status?: string,
    id?: number,
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      disableClose: true,
      data: { title, type, planList, driver_status, id },
    });
    return dialogRef.afterClosed();
  }

  openEditPlanDialog(title: string, type: string, data: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      disableClose: true,
      data: { title, type, data },
    });
    return dialogRef.afterClosed();
  }

  opensubscriptionEditDialog(
    title: string,
    type: string,
    data: any,
    payment_mode: string
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      height: 'auto',
      disableClose: true,
      data: { title, type, data, payment_mode },
    });
    return dialogRef.afterClosed();
  }

   // To show information only (ok button only)
   openInformativeDialog(
    title: string,
    message: string,
    type: string
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '670px',
      data: { title, message, type },
    });
    return dialogRef.afterClosed();
   }

  //bulk upload
  openBulkUploadDriverDialog(
    title: string,
    type: string
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      minWidth: '670px',
      height: 'auto',
      minHeight: '260px',
      disableClose: true,
      panelClass: 'bulk-upload-modal',
      data: { title, type },
    });
    return dialogRef.afterClosed();
  }
}
