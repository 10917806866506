import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { CardService } from 'src/app/services/card.service';
import { LoaderService } from './loader.service';
import { NotifierService } from './notifier.service';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  constructor(
    private stripeService: StripeService,
    private _NotifierService: NotifierService,
    private _LoaderService: LoaderService,
    private _CardService: CardService
  ) {}

  /**
   * *This method creates token for stripe payment using stripe element of cards.
   * @param clientIntent
   */
  createToken(
    clientIntent: any,
    cardElement: any,
    name: string,
    transactionId: string,
    isCardSaved: boolean
  ): Observable<any> {
    return new Observable((observer) => {
      this.stripeService
        // .createToken(cardElement, { name })
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: name,
          },
        })
        .subscribe((result) => {
          if (result) {
            if (result.paymentMethod) {
              // this._LoaderService.setState(false);
              const cardData = result.paymentMethod;
              if (cardData) {
                const card = {
                  cardId: cardData.id,
                  checkDuplicateCard: false,
                };
                this.confirmPayment(
                  clientIntent,
                  cardElement,
                  name,
                  isCardSaved
                ).subscribe((paymentResult: any) => {
                  observer.next(paymentResult); // Emit the payment result
                  if (paymentResult && isCardSaved) {
                    this.addCardInDB(card, transactionId).subscribe({
                      next: (dbResult) => {
                        observer.next(paymentResult); // Emit the payment result
                        observer.complete(); // Complete the Observable
                      },
                      error: (error) => {
                        observer.error(error); // Emit error
                        observer.complete(); // Complete the Observable
                      },
                    });
                  } else {
                    observer.next(paymentResult); // Emit the payment result
                    observer.complete(); // Complete the Observable
                  }
                });
              }
            } else if (result.error) {
              const errorMessage = this.getErrorMessage(result.error);
              this._NotifierService.showError(errorMessage ?? 'Incorrect card details. Please check', 'center');
              this._LoaderService.setState(false);
              observer.next(false); // Emit false for error
              observer.complete(); // Complete the Observable
            }
          }
        });
    });
  }

  /**
   * *This method confirms payment on stripe using card element.
   * @param clientSecret
   * @param cardElement
   * @param name
   * @param isCardSaved
   */
  confirmPayment(
    clientSecret: string,
    cardElement: any,
    name: string,
    isCardSaved: boolean
  ): Observable<boolean> {
    this._LoaderService.setState(true);
    return new Observable<boolean>((observer) => {
      this.stripeService
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
          // save_payment_method: isCardSaved,
        })
        .subscribe((result) => {
          if (result?.error && result?.error?.message) {
            const errorMessage = this.getErrorMessage(result.error);
            this._NotifierService.showError(errorMessage ?? result.error.message,'center');
            observer.next(false); // Emit false for error
          } else {
            if (result?.paymentIntent?.status === 'succeeded') {
              observer.next(true); // Emit true for successful payment
            } else {
              observer.next(false); // Emit false for payment failed
            }
          }
          observer.complete(); // Complete the Observable
        });
    });
  }

  /**
   * *This method confirms payment on stripe using card id from the card list.
   * @param clientSecret
   * @param cardId
   */
  confirmPaymentByToken(
    clientSecret: string,
    cardId: string
  ): Observable<boolean> {
    this._LoaderService.setState(true);
    return new Observable<boolean>((observer) => {
      this.stripeService
        .confirmCardPayment(clientSecret, {
          payment_method: cardId,
        })
        .subscribe((result) => {
          if (result?.error && result?.error?.message) {
            const errorMessage = this.getErrorMessage(result.error);
            this._NotifierService.showError(errorMessage ?? result.error.message);
            observer.next(false); // Emit false for error
          } else {
            if (result?.paymentIntent?.status === 'succeeded') {
              observer.next(true); // Emit true for successful payment
            } else {
              observer.next(false); // Emit false for payment failed
            }
          }
          observer.complete(); // Complete the Observable
        });
    });
  }

  /**
   * *This method to add stripe card on db using card data token and transactionId.
   * @param cardData
   * @param token
   * @param transactionId
   */
  addCardInDB(cardData: any, transactionId?: string): Observable<any> {
    this._LoaderService.setState(true);

    if (transactionId) {
      cardData = { ...cardData, transactionId: transactionId };
    }

    return new Observable<any>((observer) => {
      this._CardService.addCard(cardData).subscribe({
        next: (response: any) => {
          if (cardData.checkDuplicateCard) {
            this._NotifierService.showSuccess(response.message);
          }
          observer.next(response);
          observer.complete(); // Complete the Observable
        },
        error: (error: any) => {
          this._NotifierService.showError(error?.error?.message, 'center');
          observer.error(error); // Emit error
        },
      });
    });
  }

  /**
   * *This method to create card on stripe using card element.
   * @param cardElement
   * @param name
   */
  createCardOnStripe(cardElement: any, name: string): Observable<boolean> {
    this._LoaderService.setState(true);
    return new Observable<boolean>((observer) => {
      this.stripeService
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: name,
          },
        })
        .subscribe((result) => {
          if (result?.error && result?.error?.message) {
            const errorMessage = this.getErrorMessage(result.error);
            this._NotifierService.showError(errorMessage ?? result.error.message, 'center');
            observer.next(false); // Emit false for error
            observer.complete(); // Complete the Observable
          } else if (result.paymentMethod) {
            const card = {
              cardId: result.paymentMethod.id,
              checkDuplicateCard: true,
            };
            this.addCardInDB(card).subscribe({
              next: () => {
                observer.next(true); // Emit true for successful payment
                observer.complete(); // Complete the Observable
              },
              error: (error) => {
                observer.next(false); // Emit false for error
                observer.complete(); // Complete the Observable
              },
            });
          }
        });
    });
  }

  private getErrorMessage(error: any): string {
    const errorMessages: { [key: string]: string } = {
      // API Errors
      api_connection_error: 'Network communication with Stripe failed.',
      api_error: "An error occurred internally with Stripe's API.",
      authentication_error: "Authentication with Stripe's API failed.",
      invalid_request_error: "Invalid request to Stripe's API.",
      rate_limit_error: 'Too many requests made to the API too quickly.',

      // Card Errors
      card_declined: 'Your card was declined.',
      expired_card: 'Your card has expired.',
      incorrect_card_detail: 'Incorrect card details. Please check and then try again',
      incorrect_cvc: 'Incorrect card details. Please check and then try again',
      incorrect_number: 'The card number is incorrect.',
      insufficient_funds: 'There are insufficient funds on your card.',
      processing_error: 'An error occurred while processing the card.',
      incorrect_zip: 'The ZIP code is incorrect.',

      // Payment Intent Errors
      payment_intent_authentication_failure:
        'Authentication failed for this payment intent.',
      payment_intent_incompatible_payment_method:
        'The payment method is incompatible with the payment intent.',
      payment_intent_invalid_parameter:
        'Invalid parameters for the payment intent.',
      payment_intent_payment_attempt_failed:
        'Payment attempt failed for this payment intent.',

      // Default error message
      defaultError: 'Incorrect card details. Please check and then try again',
    };

    // Determine which error message to return based on the error received
    if (error.type === 'card_error' && error.code === 'card_declined') {
      // Handle specific decline codes for card declines
      switch (error.decline_code) {
        case 'insufficient_funds':
          return errorMessages['insufficient_funds'];
        case 'generic_decline':
          return 'Your card has been declined.';
        case 'lost_card':
          return 'Your card has been reported as lost.';
        case 'stolen_card':
          return 'Your card has been reported as stolen.';
        case 'expired_card':
          return errorMessages['expired_card'];
        case 'incorrect_cvc' || 'incorrect_zip':
          return errorMessages['incorrect_card_detail'];
        case 'card_not_supported':
          return 'This card does not support this type of purchase.';
        case 'processing_error':
          return 'An error occurred while processing your card.';
        default:
          return 'Incorrect card details. Please check and then try again';
      }
    }
    // Check for other specific error types
    if (errorMessages[error.code]) {
      return errorMessages[error.code];
    }

    // Check for API related errors
    if (error.type && errorMessages[error.type]) {
      return errorMessages[error.type];
    }

    // Fallback to error message if available
    if (error.message) {
      return error.message;
    }

    // Return default error message if none of the conditions match
    return errorMessages['defaultError'];
  }
}
