import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import {
  payment_mode,
  subscription_status,
} from 'src/app/constant/db.constant';
import { SharedDataService } from '../../services/shared-data.service';

@Component({
  selector: 'app-floating-notification',
  templateUrl: './floating-notification.component.html',
  styleUrls: ['./floating-notification.component.scss'],
})
export class FloatingNotificationComponent {
  serviceUserData!: any;
  subscription_msg: string = '';
  floating_title: string = 'SUBSCRIPTION ALERT!!';
  // subscriptionBtn: boolean = true;
  subscriptionBtn: boolean = false;
  todayISOString: string = '';
  dayDifference: number = 0;
  subEndDate: string = '';
  graceDate: string = '';
  showsubscribeMsg: boolean = false;
  subscribeMsg: string = '';

  constructor(private _SharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.getSubsData();
  }
  //to get subscription status
  getSubsData() {
    this._SharedDataService.getSubsData().subscribe((data) => {
      this.serviceUserData = data;

      this.serviceUserData.subscription !== null
        ? this.dateCalculation(this.serviceUserData.subscription.end_date)
        : '';
      this.subEndDate = this.serviceUserData?.subscription?.end_date
        ? formatDate(
            this.serviceUserData?.subscription?.end_date,
            'dd MMM yyyy',
            'en-US'
          )
        : formatDate(this.serviceUserData.expiry_date, 'dd MMM yyyy', 'en-US');
      this.graceDate = this.serviceUserData.subscription.grace_end_date
        ? formatDate(
            this.serviceUserData.subscription.grace_end_date,
            'dd MMM yyyy',
            'en-US'
          )
        : '';

      const subStatus = this.serviceUserData?.subscription?.subscription_status;
      const generateMessage = (msg: any) => {
        return this.serviceUserData.payment_mode === payment_mode.ONLINE
          ? `${msg} Select “Subscribe Now” to renew.`
          : `${msg} To renew, please contact Delm8 team.`;
      };

      //to hide/show the subscription button
      const showSubscribeBtn = (userData: any) => {
        return (
          userData.payment_mode === payment_mode.ONLINE &&
          userData.subscription.subscription_status != subscription_status.TRIAL
        );
      };

      switch (subStatus) {
        case subscription_status.TRIAL: {
          if (this.dateCalculation(this.subEndDate) === 0) {
            this.subscription_msg = 'Your subscription will be upgraded today';
          } else {
            this.subscription_msg = `Your trial account will be upgraded unless cancelled before ${this.subEndDate}.`;
          }
          if (this.serviceUserData.cancelAtPeriodEnd) {
            this.subscription_msg = `Your trial subscription has been cancelled, will continue until ${this.subEndDate}.`;
          }
          break;
        }

        case subscription_status.CANCELLED: {
          this.subscription_msg = generateMessage(
            `Your subscription was cancelled on ${this.subEndDate}.`
          );
          break;
        }

        case subscription_status.EXPIRED: {
          this.subscription_msg = generateMessage(
            `Your subscription expired on ${this.subEndDate}.`
          );
          break;
        }

        case subscription_status.ACTIVE: {
          // payment fail alert message
          if (
            !this.serviceUserData.cancelAtPeriodEnd &&
            this.serviceUserData.isUserInGrace &&
            this.serviceUserData.payment_mode === payment_mode.ONLINE
          ) {
            this.subscription_msg = generateMessage(
              `Your subscription has been ended on ${
                this.subEndDate
              }, your payment attempt has failed. We will retry over the next ${this.dateCalculation(
                this.graceDate
              )} days..`
            );
            this.floating_title = 'SUBSCRIPTION PAYMENT FAILURE NOTICE!!';
          } else {
            //the subscription is cancelled, will end at the end of the cycle
            this.serviceUserData.cancelAtPeriodEnd
              ? (this.subscription_msg = generateMessage(
                  `Your subscription has been cancelled, will continue until ${this.subEndDate}.`
                ))
              : '';

            //for corporates who have exceeded their subscription date and are in grace period
            if (this.serviceUserData.isUserInGrace) {
              this.subscription_msg = generateMessage(
                `Your subscription expired on ${this.subEndDate}, We have extended it for 3 additional days until ${this.graceDate}.`
              );
              this.floating_title = 'SUBSCRIPTION EXPIRY NOTICE!!';
            }

            //the driver exceeds allowance limit
            if (this.serviceUserData.exceedsLimit) {
              this.subscription_msg =
                'You have exceeded your driver limit. Please deactivate any unnecessary drivers within 3 days, or all drivers will be deactivated.'
                // 'You have exceeded the driver limit. Please deactivate any unnecessary drivers.';
              this.floating_title = 'DRIVER ALLOWANCE EXCEEDED!!';
            }
          }
          break;
        }
      }
      this.subscriptionBtn = showSubscribeBtn(this.serviceUserData);
    });
  }

  dateCalculation(end_date: any) {
    const currentDate = new Date();
    const endDate = new Date(end_date);

    // Remove the time portion from the dates
    const currentDateString = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const endDateString = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    // Calculate the difference in days
    const timeDifference =
      endDateString.getTime() - currentDateString.getTime();
    this.dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return this.dayDifference;
  }
}
