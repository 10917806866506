import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  IForgotPassword,
  ILogin,
  IRegister,
  IResetPassword,
} from '../_types/Auth/auth.types';
import { IAdminLogin } from '../_types/admin.types';
import { IError, IResponse } from '../_types/common.types';
import { ILoginResponse } from '../_types/corporator.types';
import { ROLES } from '../constant/db.constant';
import { SessionService } from '../shared/services/session.service';
import { NotifierService } from '../shared/services/notifier.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authAPIBaseUrl = `${environment.apiBaseUrl}auth/`;

  constructor(
    private http: HttpClient,
    private _Router: Router,
    private _SessionService: SessionService,
    private _NotifierService: NotifierService
  ) {}
  login(data: ILogin, isAdmin: boolean = false): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(
      isAdmin
        ? `${this.authAPIBaseUrl}admin/sign-in`
        : `${this.authAPIBaseUrl}corporator/sign-in`,
      data
    );
  }

  forgetPassword(
    data: IForgotPassword,
    isAdmin: boolean = false
  ): Observable<IResponse> {
    return this.http.post<IResponse>(
      isAdmin
        ? `${environment.apiBaseUrl}auth/admin/forgot-password`
        : `${environment.apiBaseUrl}auth/forgot-password`,
      data
    );
  }

  resetPassword(data: IResetPassword): Observable<IResponse> {
    return this.http.post<IResponse>(
      `${environment.apiBaseUrl}auth/reset-password`,
      data
    );
  }

  emailVerification(token: string): Observable<IResponse> {
    const url = environment.apiBaseUrl + `auth/verify-email/${token}`;
    return this.http.get<IResponse>(url);
  }

  checkEmailUnique(data: any): Observable<Partial<IResponse | IError>> {
    return this.http.post<any>(`${environment.apiBaseUrl}auth/check-email`, data);
  }
  superAdminLogin(data: any): Observable<IAdminLogin> {
    return this.http.post<any>(`${environment.apiBaseUrl}auth/admin/sign-in`, data);
  }

  onlineCorporateRegistration(data: IRegister): Observable<IResponse> {
    return this.http.post<IResponse>(
      `${environment.apiBaseUrl}auth/corporator/sign-up`,
      data
    );
  }

  logout(isLogoutMsg: boolean = false) {
    const userRole = this._SessionService.getSession('user_role');
    this._SessionService.removeLocalStorageData();
    // this._NotifierService.showSuccess('Logged out successfully..');
    if(isLogoutMsg){
      if (userRole === ROLES.CORPORATOR) {
        this._NotifierService.showSuccess('Logged out successfully.');
        this._Router.navigate(['/login']);
      } else if (userRole === ROLES.ROLE_SUPER_ADMIN) {
        this._NotifierService.showSuccess('Logged out successfully.');
        this._Router.navigate(['/admin-login']);
      } else {
        this._NotifierService.showSuccess('Logged out successfully.');
        this._Router.navigate(['/login']);
      }
    }
    else{
      this._Router.navigate(['/login']);  
    }
  }
  sendOtp(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}auth/send-otp`, data);
  }

  verifyOtp(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}auth/verify-otp`, data);
  }

  validateCaptchaToken(captchaToken: string): Observable<IResponse> {
    return this.http.post<any>(`${environment.apiBaseUrl}auth/validate-captcha`, {
      captchaToken,
    });
  }
}
