import { Component } from '@angular/core';

@Component({
  selector: 'app-app-layput',
  templateUrl: './app-layput.component.html',
  styleUrls: ['./app-layput.component.scss']
})
export class AppLayputComponent {

}
