import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDriverStatusPayload } from '../_types/driver.types';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(private http: HttpClient) {}
  driverCount(): Observable<any> {
    const url = `${apiConfig.driver}/count`;
    return this.http.get<any>(url);
  }

  getDriverList(
    paginationObj: any,
    filterObj: any,
    orderBy: string = 'DESC',
    isAdmin: boolean = false,
    id?: number,
    sortingOptions?: any,
  ): Observable<any> {
    let url = `${apiConfig.driver}/list?page=${
      paginationObj.currentPage + 1
    }&pageSize=${paginationObj.pageSize}&orderBy=${orderBy.toUpperCase()}`;

    if (
      filterObj?.searchText !== undefined &&
      filterObj?.searchText.trim() !== ''
    ) {
      url += `&search=${filterObj?.searchText.trim()}`;
    }
    if (
      filterObj?.status !== undefined &&
      filterObj?.status !== '' &&
      filterObj?.status !== 'all'
    ) {
      url += `&status=${filterObj?.status}`;
    }
    if (isAdmin) {
      url += `&corporateId=${id}`;
    }
    return this.http.post<any>(url, sortingOptions);
  }

  getFormerDriverList(
    paginationObj: any,
    searchText?: string,
    orderBy: string = 'DESC',
    isAdmin: boolean = false,
    id?: number,
    sortingOptions?: any,
  ): Observable<any> {
    let url = `${apiConfig.driver}/driver-history?page=${
      paginationObj.currentPage + 1
    }&pageSize=${paginationObj.pageSize}&orderBy=${orderBy.toUpperCase()}`;

    if (searchText !== undefined && searchText.trim() !== '') {
      url += `&search=${searchText.trim()}`;
    }
    if (isAdmin) {
      url += `&corporateId=${id}`;
    }
    return this.http.post<any>(url, sortingOptions);
  }
  verifyEmail(value: string, fieldName: string): Observable<any> {
    // Encode telephone number
    const url = `${environment.apiBaseUrl}/driver/driver-exists?${
      fieldName === 'email'
        ? `email=${value}`
        : `telephone_number=${encodeURIComponent(value)}`
    }`;
    return this.http.get<any>(url);
  }

  addDriver(data: any): Observable<any> {
    const url = `${apiConfig.driver}/add`;
    return this.http.post<any>(url, data);
  }

  getDriverDataById(userId: number): Observable<any> {
    const url = `${apiConfig.driver}/${userId}`;
    return this.http.get<any>(url);
  }

  updateDriver(data: any, id: number): Observable<any> {
    const url = `${apiConfig.driver}/${id}`;
    return this.http.patch<any>(url, data);
  }

  updateDriverStatus(data: IDriverStatusPayload): Observable<any> {
    return this.http.patch<any>(`${apiConfig.driver}/status`, data);
  }

  driverLeaveCompany(id: number): Observable<any> {
    return this.http.post<any>(`${apiConfig.corporate}/leave-driver/${id}`, {});
  }

  deleteFormerDriver(id: number): Observable<any> {
    return this.http.delete<any>(
      `${apiConfig.driver}/delete-former-driver/${id}`,
      {}
    );
  }

  uploadBulkDriver(data: any): Observable<any> {
    const url = apiConfig.driver + `/bulk-add`;
    return this.http.post<any>(url, data);
  }
}
