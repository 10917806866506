import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, fromEvent, tap } from 'rxjs';
import { ROLES } from 'src/app/constant/db.constant';
import { SessionService } from '../../services/session.service';
import { DatepickerComponent } from '../datepicker/datepicker.component';

@Component({
  selector: 'app-table-controls',
  templateUrl: './table-controls.component.html',
  styleUrls: ['./table-controls.component.scss'],
})
export class TableControlsComponent {
  constructor(private _SessionService: SessionService) {}

  @ViewChild('searchInput')
  searchInput!: ElementRef;
  userRole: ROLES | null = null;
  isAdmin: boolean = false;
  selectedOption: string = 'all';
  selectedSubsDurationOption: string = 'all';
  selectedSubsStatusOption: string = 'all';
  @Input() placeholder: string = 'Search';
  @Input() showElementList: string[] = [];
  @Input() title: string = '';
  @Input() statusOptionList: any;
  @Input() subDurationTitle: string = '';
  @Input() subStatusTitle: string = '';
  @Input() subsDurationOptionList: any;
  @Input() subsStatusOptionList: any;
  @Input() filterTitle: string = 'Status';
  @Input() showTotalRecordsBelowFilter: boolean = false;
  @Output() handleSearch$: EventEmitter<any> = new EventEmitter();
  @Output() handleAddNew$: EventEmitter<any> = new EventEmitter();
  @Output() handleBulkUpload$: EventEmitter<any> = new EventEmitter();
  @Output() handleFilterOptionChange$: EventEmitter<any> = new EventEmitter();
  @Output() handleSubsDurationOptionChange$: EventEmitter<any> =
    new EventEmitter();
  @Output() handleSubsStatusOptionChange$: EventEmitter<any> =
    new EventEmitter();
  dateRange!: { start: Date | null; end: Date | null };
  @Output() handleDateRangeChange = new EventEmitter<{
    start: Date | null;
    end: Date | null;
  }>();
  @Input() dateRangeTooltipMsg: string = ''; //passing to listing component
  @Output() handleResetFilter$: EventEmitter<any> = new EventEmitter();

  @ViewChild(DatepickerComponent)
  public datepickerComponent!: DatepickerComponent;

  handleTextChangeSearch(searchValue: any) {
    if (searchValue.length) {
      searchValue.trim().length && this.handleSearch$.emit(searchValue);
    } else {
      this.handleSearch$.emit(searchValue);
    }
  }

  ngOnInit() {
    this.userRole = this._SessionService.getSession('user_role') as ROLES;
    this.isAdmin = this.userRole === ROLES.ROLE_SUPER_ADMIN;
  }

  ngAfterViewInit() {
    this.showElementList.includes('search')
      ? fromEvent(this.searchInput.nativeElement, 'keyup')
          .pipe(
            debounceTime(800),
            distinctUntilChanged(),
            tap(() => {
              // this.searchInput.nativeElement.value.trim().length &&
              this.handleTextChangeSearch(this.searchInput.nativeElement.value);
            })
          )
          .subscribe()
      : null;
  }

  handleAddNew() {
    this.handleAddNew$.emit();
  }

  handleBulkUpload() {
    this.handleBulkUpload$.emit();
  }
  handleFilterOptionChange(event: MatSelectChange) {
    this.handleFilterOptionChange$.emit(event.value);
  }

  handleSubsDurationOptionChange(event: MatSelectChange) {
    this.handleSubsDurationOptionChange$.emit(event.value);
  }
  handleSubsStatusOptionChange(event: MatSelectChange) {
    this.handleSubsStatusOptionChange$.emit(event.value);
  }

  onDateRangeChange(range: { start: Date | null; end: Date | null }) {
    this.dateRange = range;
    this.dateRange ? this.handleDateRangeChange.emit(this.dateRange) : ''; // Emit to organizsation list component
  }

  handleResetFilter() {
    const isDefaultValues =
      this.searchInput.nativeElement.value === '' &&
      (!this.dateRange ||
        (this.dateRange.start === null && this.dateRange.end === null)) &&
      this.selectedOption === 'all' &&
      this.selectedSubsDurationOption === 'all' &&
      this.selectedSubsStatusOption === 'all';

    if (!isDefaultValues) {
      // Reset search input
      this.searchInput.nativeElement.value = '';

      // Reset date range
      this.dateRange = { start: null, end: null };
      this.datepickerComponent?.range.reset();

      // Reset filter options
      this.selectedOption = 'all';
      this.selectedSubsDurationOption = 'all';
      this.selectedSubsStatusOption = 'all';

      this.handleResetFilter$.emit(true);
    }

    this.handleResetFilter$.emit(false);
  }

  selectedOptionValue(selectionList: any[], keyName: string): string {
    const selected = selectionList.find(
      (option: any) => option.key === keyName
    );
    return selected ? selected.value : '';
  }
}
