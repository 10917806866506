/** handle http error */

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../shared/services/loader.service';
import { NotifierService } from '../shared/services/notifier.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _Router: Router,

    private _AuthService: AuthService,
    private _LoaderService: LoaderService,
    private _NotifierService: NotifierService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((requestError: HttpErrorResponse) => {
        if (!requestError?.status) {
          requestError.error.message =
            'Something went wrong, Please try again...';
        } else if (
          requestError?.status === 401 ||
          requestError?.status === 403
        ) {
          this._NotifierService.showError(requestError.error.message);
          this._AuthService.logout();
        }
        this._LoaderService.setState(false);
        return throwError( requestError?.error);
      })
    );
  }
}
