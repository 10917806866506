import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { IError, IResponse } from './_types/common.types';
import { UserData } from './_types/corporator.types';
import { ROLES } from './constant/db.constant';
import { OrganizationService } from './services/organization.service';
import { LoaderService } from './shared/services/loader.service';
import { SessionService } from './shared/services/session.service';
import { SharedDataService } from './shared/services/shared-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _Router: Router,
    private _LoaderService: LoaderService,
    private _OrganizationService: OrganizationService,
    private _SessionService: SessionService,
    private _SharedDataService: SharedDataService,
  ) {
    this._Router.events.subscribe((data: any) => {
      this.routerInterceptor(data);
    });
  }

  ngOnInit(): void {
    // Subscribe to router events
    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Call API on every route change
        let user_role = this._SessionService.getSession('user_role');
        let data = this._SessionService.getSession('data');
        let userId = data ? JSON.parse(data).id : '';
        if (user_role === ROLES.CORPORATOR) {
          this.getUserProfile(false, userId);
        }
      }
    });
  }

  getUserProfile(isAdmin: boolean, userId?: number) {
    this._OrganizationService.getUserProfile(isAdmin, userId).subscribe({
      next: (response: IResponse) => {
        if (response.success) {
          this._SessionService.setSession({
            userData: JSON.stringify(response.data),
          });
          this.setCorporateData(response.data as UserData);
        }
        this._LoaderService.setState(false);
      },
      error: (error: IError) => {
        console.log('Inside error get corporate===>>', error);
      },
    });
  }

  setCorporateData(userData: UserData): void {
    this._SharedDataService.setSubsData(userData);
  }

  routerInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this._LoaderService.setState(true);
    } else if (event instanceof NavigationCancel) {
      this._LoaderService.setState(false);
    } else if (event instanceof NavigationError) {
      this._LoaderService.setState(false);
    } else if (event instanceof NavigationEnd) {
      this._LoaderService.setState(false);
    }
  }
}
