import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreLoginLayoutComponent } from './shared/components/pre-login-layout/pre-login-layout.component';
import { AppLayputComponent } from './shared/components/app-layput/app-layput.component';
import { AuthGuard } from './guard/auth.guard';
import { ROLES } from './constant/db.constant';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', title: 'Login' },
  {
    path: '',
    component: PreLoginLayoutComponent,
    loadChildren: () =>
      import('./modules/authorization/authorization.module').then(
        (authorization) => authorization.AuthorizationModule
      ),
  },
  {
    path: 'corporate',
    component: AppLayputComponent,
    canActivate: [AuthGuard],
    data: { roles: [ROLES.CORPORATOR] },
    loadChildren: () =>
      import('./modules/corporate/corporate.module').then(
        (corporate) => corporate.CorporateModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: { roles: [ROLES.ROLE_SUPER_ADMIN] },
    component: AppLayputComponent,
    loadChildren: () =>
      import('./modules/admin/admin.module').then((admin) => admin.AdminModule),
  },
  // {
  //   path: 'not-found',
  //   component: NotFoundComponent
  // },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
