import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ROLES } from '../constant/db.constant';
import { AuthService } from '../services/auth.service';
import { SessionService } from '../shared/services/session.service';
import { NotifierService } from '../shared/services/notifier.service';

@Injectable()
export class AuthGuard {
  public token: string | null = '';
  public userRole: ROLES | null = null;
  constructor(
    private _Router: Router,
    private _SessionService: SessionService,
    private _AuthService: AuthService,
    private _NotifierService: NotifierService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.token = this._SessionService.getSession('access_token');
    this.userRole = this._SessionService.getSession('user_role') as ROLES;
    if (this.token && this.userRole) {
      // check if route is restricted by role
      const { roles } = route.data;
      if (roles && !roles.includes(this.userRole)) {
        // role not authorized so redirect to home page
        this._NotifierService.showError('Access Denied');
        this._AuthService.logout();
        this._Router.navigate(['/']);
        return of(false);
      }
      // authorized so return true
      return of(true);
    } else {
      this._AuthService.logout();
      this._Router.navigate(['/']);
      return of(false);
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
