import { environment } from 'src/environments/environment';

export const apiConfig = {
  admin: environment.apiBaseUrl + 'admin',
  corporate: environment.apiBaseUrl + 'corporator',
  auth: environment.apiBaseUrl + 'auth',
  driver: environment.apiBaseUrl + 'driver',
  invoice: environment.apiBaseUrl + 'invoice',
  subscription: environment.apiBaseUrl + 'subscription',
  plan: environment.apiBaseUrl + 'plan',
  card: environment.apiBaseUrl + 'card',
};
