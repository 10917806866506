import { Location } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import { NotifierService } from '../../services/notifier.service';
import { LoaderService } from '../../services/loader.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { SessionService } from '../../services/session.service';
import { ROLES, payment_mode } from 'src/app/constant/db.constant';
import { SharedDataService } from '../../services/shared-data.service';
import * as moment from 'moment';
import { UserData } from 'src/app/_types/corporator.types';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent {
  @ViewChild('printWrapper') printWrapper!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  editId: any;
  invoiceData: any;
  userRole: any;
  link: any;
  isAdmin: boolean = false;
  todayDate = new Date();
  subscriptionData!: UserData;
  payment_mode!: payment_mode;

  constructor(
    public route: ActivatedRoute,
    private _NotifierService: NotifierService,
    private _LoaderService: LoaderService,
    public _router: Router,
    private location: Location,
    private _InvoiceService: InvoicesService,
    private _SessionService: SessionService,
    private _SharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this._SharedDataService.setHeaderTitle('Invoice');
    this.userRole = this._SessionService.getSession('user_role') as ROLES;
    this.isAdmin = this.userRole === ROLES.ROLE_SUPER_ADMIN;
    this.editId = this.route.snapshot.paramMap.get('id')
      ? this.route.snapshot.paramMap.get('id')
      : undefined;
    this._LoaderService.setState(true);
    this.getInvoiceById(this.editId);
    if(!this.isAdmin) this.getSubsData();
  }

  getSubsData() {
    this._SharedDataService.getSubsData().subscribe((data) => {
      this.subscriptionData = data;
    });
  }

  getInvoiceById(id: number) {
    this._InvoiceService
      .getInvoiceById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: any) => {
          this._LoaderService.setState(false);
          if (response.success === true && response.data) {
            this.invoiceData = {
              ...response?.data,
              isOfflineInvoice: this.isOfflineInvoice(
                response?.data?.invoice_no
              ),
            };
            this.payment_mode = response?.data?.corporator?.payment_mode;
          } else {
            this._router.navigate([
              `${
                this.isAdmin ? 'admin/invoice/list' : 'corporate/invoice/list'
              }`,
            ]);
            this._NotifierService.showError('No invoice found');
          }
        },
        error: (error) => {
          this.location.back();
          this._NotifierService.showError(error.message);
        },
      });
  }

  isOfflineInvoice(invoiceNo: string) {
    return invoiceNo.startsWith('OFL') || invoiceNo.startsWith('INV-OFL');
  }

  downloadInvoice() {
    const templateElement = document.getElementById('print-wrapper');
    const companyName = this.invoiceData.corporator.company_name.replace(/([a-z])([A-Z])/g, '$1_$2').replace(/\s/g, '_');
    const formattedDate = moment(this.todayDate).format('DD-MM-yyyy')

    if (templateElement) {
      const templateContent = templateElement.innerHTML;
      var opt = {
        margin: 1,
        filename: `${companyName}_${formattedDate}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, dpi: 192, letterRendering: true },
        jsPDF: {
          putOnlyUsedFonts: true,
          floatPrecision: 'smart',
          orientation: 'p',
        },
      };
      // This will implicitly create the canvas and PDF objects before saving.
      html2pdf().from(templateContent).set(opt).save();
    }
  }

  printInvoice() {
    const templateElement = this.printWrapper.nativeElement;
    if (!templateElement) {
      console.error('Invoice content element not found');
      return;
    }

    const templateContent = templateElement.innerHTML;
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) {
      console.error('Failed to access iframe document');
      return;
    }

    iframeDoc.open();
    iframeDoc.write(templateContent);
    iframeDoc.close();

    // Copy styles from component to iframe content
    const styles = document.querySelectorAll('style');
    styles.forEach((style) => {
      iframeDoc.head.appendChild(style.cloneNode(true));
    });

    // Listen for the 'afterprint' event to remove the iframe
    iframe.contentWindow?.addEventListener('afterprint', () => {
      document.body.removeChild(iframe);
    });
    iframe.contentWindow?.print();
  }

  backToLastPage() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
