<div class="subscription-wrapper" *ngIf="this.subscriptionDetails">
  <form [formGroup]="form">
    <div class="subscription-content">
      <h1>Delm8 flexible packages</h1>
      <p>You can change your package any time.</p>
      <p>
        Please note that for annual account holders the change will take effect
        at your next renewal date.
      </p>
    </div>
    <div class="plan-list" *ngIf="this.activeUpcomingPlanList.length">
      <div
        [ngClass]="{
          'plan-block': true,
          'active-plan': plan.id == this.subscriptionDetails.plan_id
        }"
        *ngFor="let plan of this.activeUpcomingPlanList; let i = index"
      >
        <input
          type="radio"
          name="planId"
          id="{{ plan.id }}"
          [formControlName]="'planId'"
          [value]="plan.id"
          checked
        />
        <div class="plan-inner">
          <div class="d-flex justify-content-between">
            <h2>
              DELM8
              {{
                plan.plan_name
                  ? (plan.plan_name | uppercase)
                  : ("Enterprise" | uppercase)
              }}
            </h2>
            <div class="chip-tooltip-wrapper">
              <mat-chip
                highlighted
                *ngIf="plan.duration == this.subscriptionDetails.duration"
                class="status"
                [ngClass]="{
                  'status-green':
                    this.subscriptionDetails.subscription_status === 'active',
                  'status-red':
                    this.subscriptionDetails.subscription_status ===
                    'in_active',
                  'status-orange':
                    this.subscriptionDetails.subscription_status === 'trial',
                  'status-grey':
                    this.subscriptionDetails.subscription_status ===
                      'expired' ||
                    this.subscriptionDetails.subscription_status === 'cancelled'
                }"
              >
                {{ this.subscriptionDetails.subscription_status | titlecase }}
                Plan
              </mat-chip>
              <mat-chip
                *ngIf="
                  plan.id ==
                    this.subscriptionDetails.upcoming_subscription?.plan_id &&
                  this.subscriptionDetails.subscription_mode !== 'offline'
                "
                highlighted
              >
                Upcoming Plan
              </mat-chip>

              <img
                src="assets/images/icons/lucide_info.svg"
                alt="i-icon"
                class="info-icon"
                (mouseenter)="activeTooltipIndex = i"
                (mouseleave)="activeTooltipIndex = -1"
                *ngIf="
                  subscriptionDetails.subscription_status !== 'expired' &&
                  subscriptionDetails.subscription_status !== 'cancelled'
                "
              />
              <div class="tooltip" *ngIf="activeTooltipIndex === i">
                <div class="d-flex tooltip-wrapper">
                  <div class="d-flex tooltip-inner">
                    <span class="tooltip-title">Start Date</span>
                    {{ tooltipDataMap[i].startDate | date : "mediumDate" }}
                  </div>
                  <div class="d-flex tooltip-inner">
                    <span class="tooltip-title">Expiry Date</span>
                    {{ tooltipDataMap[i].endDate | date : "mediumDate" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="plan.duration !== 'custom'">
            <strong>£{{ plan.per_driver_price }}</strong
            >/ Per user per
            {{ plan.duration === "monthly" ? "month" : "year" }}
          </p>
          <p *ngIf="plan.duration === 'custom'">
            <strong>Custom Package</strong>
          </p>
          <ul>
            <li *ngIf="plan.duration === 'custom'">
              <mat-icon>check_circle</mat-icon> Custom Package built for your
              organisation
            </li>
            <li *ngIf="plan.duration === 'monthly'">
              <mat-icon>check_circle</mat-icon> Rolling Monthly
            </li>
            <li *ngIf="plan.duration === 'annual'">
              <mat-icon>check_circle</mat-icon> 2 Months free
            </li>
            <li><mat-icon>check_circle</mat-icon> Cancel Anytime</li>
            <li>
              <mat-icon>check_circle</mat-icon> Add or remove drivers online
            </li>
            <li>
              <mat-icon>check_circle</mat-icon> Enable and disable drivers with
              ease
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- The subscription is cancelled, will end at the end of the cycle -->
    <div
      class="cancelAtNote"
      *ngIf="this.subscriptionDetails?.cancel_at_period_end"
    >
      <span
        >Note: Your subscription has been marked for cancellation. It will stop
        working on
        {{ this.subscriptionDetails.end_date | date : "dd MMM yyyy" }}.</span
      >
    </div>

    <!-- For corporates who have exceeded their subscription date and are in grace period -->
    <div
      class="cancelAtNote"
      *ngIf="!this.subscriptionDetails?.cancel_at_period_end && this.subscriptionDetails?.is_in_grace_period"
    >
      <span
        >Note: Your subscription expired on
        {{ this.subscriptionDetails.end_date | date : "dd MMM yyyy" }}
        <span *ngIf="this.subscriptionDetails?.grace_end_date">
          , We have extended it for 3 additional days until
          {{
            this.subscriptionDetails.grace_end_date | date : "dd MMM yyyy"
          }}</span
        >
        .</span
      >
    </div>
    <div class="plan-list other-plans" *ngIf="otherPlanList.length">
      <h2>Other Plans</h2>
      <div class="plan-block" *ngFor="let plan of otherPlanList">
        <div [ngClass]="{ disabled: setDisabledClass(plan) }">
          <input
            type="radio"
            name="planId"
            id="{{ plan.duration }}"
            [formControlName]="'planId'"
            [value]="plan.id"
          />
          <div class="plan-inner">
            <div class="d-flex justify-content-between">
              <h2>
                DELM8
                {{
                  plan.plan_name
                    ? (plan.plan_name | uppercase)
                    : ("Enterprise" | uppercase)
                }}
              </h2>
              <mat-chip
                highlighted
                *ngIf="plan.duration === 'custom'"
                color="primary"
              >
                <a href="https://delm8.com/contact-us/" target="_blank"
                  >Contact Us</a
                >
              </mat-chip>
              <mat-chip
                highlighted
                *ngIf="plan.duration === 'annual'"
                color="primary"
              >
                Most Popular
              </mat-chip>
            </div>
            <p *ngIf="plan.duration !== 'custom'">
              <strong>£{{ plan.per_driver_price }}</strong
              >/ Per user per 
              {{ plan.duration === "monthly" ? "month" : "year" }}
            </p>
            <p *ngIf="plan.duration === 'custom'">
              <strong>Custom Package</strong>
            </p>
            <ul>
              <li *ngIf="plan.duration === 'monthly'">
                <mat-icon>check_circle</mat-icon> Rolling Monthly
              </li>
              <li *ngIf="plan.duration === 'annual'">
                <mat-icon>check_circle</mat-icon> 2 Months free
              </li>
              <li *ngIf="plan.duration === 'custom'">
                <mat-icon>check_circle</mat-icon> Custom Package built for your
                organisation
              </li>
              <li><mat-icon>check_circle</mat-icon> Cancel Anytime</li>
              <li>
                <mat-icon>check_circle</mat-icon> Add or remove drivers online
              </li>
              <li>
                <mat-icon>check_circle</mat-icon> Enable and disable drivers
                with ease
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button
        mat-button
        disabled="{{ disableCancleButton() }}"
        class="primary-btn cancel-btn"
        (click)="cancelSubscriptionModal()"
      >
        Cancel Plan
      </button>
      <button
        mat-button
        disabled="{{ disableChangePlanButton() }}"
        class="primary-btn"
        (click)="changeSubscription()"
      >
        {{ isPlanActive() ? "Change Plan" : "Choose Plan" }}
      </button>
    </div>
  </form>
</div>
