import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: HttpClient) {}

  getSubscriptionDetails(): Observable<any> {
    const url = `${apiConfig.corporate}/subscription`;
    return this.http.get<any>(url);
  }

  cancelSubscription(): Observable<any> {
    const url = apiConfig.subscription + `/cancel-subscription`;
    return this.http.post<any>(url, null);
  }

  changeOnlineSubscription(data: any): Observable<any> {
    const url = apiConfig.subscription + `/change-subscription`;
    return this.http.post<any>(url, data);
  }

  addSubscription(data: any): Observable<any> {
    const url = apiConfig.subscription + `/add-subscription`;
    return this.http.post<any>(url, data);
  }

  cancelUpcomingOnlineSubscription(): Observable<any> {
    const url = apiConfig.subscription + `/cancel-upcoming`;
    return this.http.patch<any>(url, null);
  }

}
