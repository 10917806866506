<div class="header-wrapper">
    <h5>{{_SharedDataService.headerTitle | async }}</h5>
    <div class="userProfile-wrapper">
        <div class="notification-icon">
            <mat-icon>notifications</mat-icon>
        </div>
        <div class="d-flex userProfile-header" *ngIf="userProfileData">
            <span class="userProfile-name">{{userProfileData.company_name ? userProfileData.company_name : userProfileData.first_name + ' ' + userProfileData.last_name }}</span>
            <div *ngIf="!isAdmin; else elseBlock"><span class="userProfile-plan" >{{getPlanName() + ' '+'PLAN' | uppercase}}</span></div>
            <ng-template #elseBlock><span class="userProfile-plan">SUPER ADMIN</span></ng-template>
        </div>
        <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="down-icon">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="isAdmin ? '/admin/profile' : '/corporate/profile'" >My Profile</button>
                <button mat-menu-item (click)="logout()">Logout</button>
              </mat-menu>
        </div>
    </div>
</div>
