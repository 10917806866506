<div
  class="d-flex search-wrapper"
  [ngClass]="{ 'justify-content-end': showTotalRecordsBelowFilter }"
>
  <div *ngIf="!showTotalRecordsBelowFilter">
    <span class="table-title">{{ title }}</span>
  </div>
  <div class="search-wrapper-inner">
    <app-datepicker
      type="range"
      *ngIf="showElementList.includes('dateRangePicker')"
      (dateRangeChange)="onDateRangeChange($event)"
      [dateRangeTooltipMsg]="dateRangeTooltipMsg"
      (handleResetFilter$)="handleResetFilter()"
    ></app-datepicker>

    <!-- For payment mode filter -->
    <div class="custom-select" *ngIf="showElementList.includes('statusFilter')">
      <span>{{ filterTitle }}:</span>
      <mat-form-field>
        <mat-select
          [(value)]="selectedOption"
          (selectionChange)="handleFilterOptionChange($event)"
          [matTooltip]="selectedOptionValue(statusOptionList, selectedOption)"
          matTooltipClass="custom-tooltip"
        >
          <mat-option
            *ngFor="let option of statusOptionList"
            [value]="option.key"
            >{{ option.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <!-- For subscription duration filter -->
    <div
      class="custom-select"
      *ngIf="showElementList.includes('subsDurationFilter')"
    >
      <span>{{ subDurationTitle }}:</span>
      <mat-form-field>
        <mat-select
          [(value)]="selectedSubsDurationOption"
          (selectionChange)="handleSubsDurationOptionChange($event)"
          [matTooltip]="
            selectedOptionValue(
              subsDurationOptionList,
              selectedSubsDurationOption
            )
          "
          matTooltipClass="custom-tooltip"
        >
          <mat-option
            *ngFor="let subOption of subsDurationOptionList"
            [value]="subOption.key"
            >{{ subOption.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <!-- For subscription status filter -->
    <div
      class="custom-select"
      *ngIf="showElementList.includes('subsStatusFilter')"
    >
      <span>{{ subStatusTitle }}:</span>
      <mat-form-field>
        <mat-select
          [(value)]="selectedSubsStatusOption"
          (selectionChange)="handleSubsStatusOptionChange($event)"
          [matTooltip]="
            selectedOptionValue(subsStatusOptionList, selectedSubsStatusOption)
          "
          matTooltipClass="custom-tooltip"
        >
          <mat-option
            *ngFor="let subStatusOption of subsStatusOptionList"
            [value]="subStatusOption.key"
            >{{ subStatusOption.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field
      *ngIf="showElementList.includes('search')"
      appearance="outline"
      class="search-box"
    >
      <mat-icon class="search-icon" matPrefix color="secondary"
        >search</mat-icon
      >
      <input
        #searchInput
        matInput
        placeholder="{{ placeholder }}"
        name="search"
        type="text"
        label="Search"
        iconName="search"
      />
    </mat-form-field>
    <button
      class="reset-btn"
      mat-icon-button
      *ngIf="showElementList.includes('reset-btn')"
      (click)="handleResetFilter()"
      matTooltip="Reset Filter"
      matTooltipClass="custom-tooltip"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      *ngIf="showElementList.includes('bulk_upload')"
      mat-flat-button
      class="button-wrapper"
      (click)="handleBulkUpload()"
    >
      Bulk Upload
    </button>
    <button
      *ngIf="showElementList.includes('add_new')"
      type="button"
      mat-flat-button
      (click)="handleAddNew()"
      class="primary-btn button-wrapper"
    >
      Add New
    </button>
  </div>
</div>
<div *ngIf="showTotalRecordsBelowFilter" class="title-wrapper">
  <span class="table-title">{{ title }}</span>
</div>
