import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit, OnDestroy {
  isBusy: boolean;
  alive: boolean;
  constructor(private _LoaderService: LoaderService) {
    this.isBusy = false;
    this.alive = true;
  }
  ngOnInit() {
    this._LoaderService
      .getState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.isBusy = data;
      });
  }
  ngOnDestroy() {
    this.alive = false;
  }
}
