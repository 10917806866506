import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nestedObjectValue' })
export class NestedObjectValuePipe implements PipeTransform {
  dividedAmount: boolean = false;
  transform(
    obj: any,
    nestedObjectName: string,
    isName: boolean = false
  ): string {
    if (!obj || !nestedObjectName) return '';
    let value = this.getValueFromObject(obj, nestedObjectName);
    if (isName) {
      nestedObjectName = nestedObjectName.replace('first_name', 'last_name');
      const lastName = this.getValueFromObject(obj, nestedObjectName);
      value = `${value} ${lastName}`;
    }
    return value;
  }

  getValueFromObject(obj: any, nestedObjectName: string) {
    const nestedObjectNames = nestedObjectName.split('.');
    let value = obj;

    for (const name of nestedObjectNames) {
      if (name === 'amount' && !this.dividedAmount && value.amount) {
        value.amount = value.amount / 100;
        this.dividedAmount = true;
      }
      else if(name === 'subscription_status' && obj?.cancelled_at_period_end || obj?.subscriptions?.is_active_till_end){
        value.subscription_status = 'Active Till End'
      }
      else if(name === 'subscription_status' && obj.isUserInGrace){
        value.subscription_status = 'In grace day'
      }
      value = value[name];
      if (!value) break;
    }
    return value;
  }
}
