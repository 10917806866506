import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  constructor(private http: HttpClient) {}

  invoiceList(
    paginationObj: any,
    orderBy: string = 'DESC',
    isAdmin: boolean,
    id?: number | null,
    pathName?: string,
    startDate?: string,
    endDate?: string,
    searchText?: string,
    sortingOptions?: any,
  ): Observable<any> {
    let url;

    if (isAdmin && pathName?.includes('admin')) {
      url = `${apiConfig.invoice}/list-all-invoice?page=${
        paginationObj.currentPage + 1
      }&pageSize=${paginationObj.pageSize}&orderBy=${orderBy.toUpperCase()}`;
    } else {
      url = isAdmin
        ? `${apiConfig.invoice}/list/${id}?page=${
            paginationObj.currentPage + 1
          }&pageSize=${paginationObj.pageSize}&orderBy=${orderBy.toUpperCase()}`
        : `${apiConfig.invoice}/list/?page=${
            paginationObj.currentPage + 1
          }&pageSize=${
            paginationObj.pageSize
          }&orderBy=${orderBy.toUpperCase()}`;
    }
    if (startDate !== undefined && startDate !== '') {
      url += `&startDate=${startDate}`;
    }
    if (endDate !== undefined && endDate !== '') {
      url += `&endDate=${endDate}`;
    }
    if (searchText !== undefined && searchText !== '') {
      url += `&search=${searchText}`;
    }
    return this.http.post<any>(url, sortingOptions);
  }

  getInvoiceById(id: number) {
    const url = `${apiConfig.invoice}/view/${id}`;
    return this.http.get<any>(url);
  }
}
