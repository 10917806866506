<div class="notification-wrapper">
    <img class="ripple-img" src="assets/images/floating-notification 1.png" />
    <div class="notification-inner">
        <div class="notification-icon">
            <ng-container *ngIf="!(serviceUserData.exceedsLimit || serviceUserData.isUserInGrace) ; else warningCase">
                <img src="assets/images/icons/Icon.svg" alt="clock-icon">
            </ng-container>
            <ng-template #warningCase>
                <img src="assets/images/icons/warning_icon.svg" alt="warning-icon">
            </ng-template>
        </div>
        <div class="d-flex data-wrapper">
            <div class="data-wrapper-inner">
                <span class="notification-title">{{floating_title}}</span>
                <span>{{subscription_msg}}</span>
            </div>
            <div>
                <button mat-flat-button class="subscribe-now" routerLink="/corporate/subscription"
                    *ngIf="subscriptionBtn">Subscribe Now</button>
            </div>
        </div>
    </div>
</div>