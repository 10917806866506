<div class="sidebar-wrapper" [ngClass]="{ 'collapsed': isCollapsed }">
  <div class="sidebar-logo-container">
    <a [routerLink]="isAdmin ? '/admin/dashboard' : '/corporate/dashboard'">
      <img class="icon-logo" src="/assets/images/delm8-logo.png" alt="logo" />
      <img class="collapse-logo" src="assets/images/icons/logo-delm8 1.svg" alt="logo" />
    </a>
    <div class="icon-back d-flex">
      <img *ngIf="!isCollapsed" src="assets/images/icons/chevrons-left.svg" alt="back-icon" (click)="toggleSidebar()">
      <img *ngIf="isCollapsed" src="assets/images/icons/chevrons-right.svg" alt="back-icon" (click)="toggleSidebar()">
    </div>
  </div>
  <div class="sidebar-content-wrapper">
    <ul>
      <li class="title-wrapper" *ngFor="let link of sidebarListByRoles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" [ngClass]="{ 'disabled-sidebar': link.disable }">
        <a class="title-wrapper-content" href="https://delm8.com/corp-package/" target="_blank" *ngIf="link.name == 'Help & Support'; else otherNavbarLink">
          <img class="icon-wrapper" [src]="link.icon" />
          <img class="icon-wrapper active-icon" [src]="link.activeIcon" />
          <span>{{ link.name }}</span>
        </a>
        <ng-template #otherNavbarLink>
          <a class="title-wrapper-content" [routerLink]="link.path">
            <img class="icon-wrapper" [src]="link.icon" />
            <img class="icon-wrapper active-icon" [src]="link.activeIcon" />
            <span>{{ link.name }}</span>
          </a>
        </ng-template>
      </li>
    </ul>
  </div>


</div>
