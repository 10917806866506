import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
} from '@angular/forms';
import { PatternKey, patterns } from 'src/app/validators/pattern_validation';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() control: any = new FormControl();
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: string = '';
  @Input() classes: string = '';
  @Input() errorMessage: string = '';
  @Input() isTelephone: boolean = false;
  @Input() isTooltipVisible: boolean = false;
  @Input() tooltipMessage: string = '';
  @Input() maxlength: string = '50';
  @Output() valueChanged$: EventEmitter<any> = new EventEmitter();
  @Output() blurEvent = new EventEmitter<void>();
  patterns = patterns;
  hidePassword: boolean = false;

  ngOnInit() {
    this.hidePassword = this.type === 'password' ? true : false;
  }

  onChange(event: any) {
    this.valueChanged$.emit(event.target.value);
  }

  onBlur() {
    // Emit blur event
    this.blurEvent.emit();
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
