import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { SortingOption } from 'src/app/_types/common.types';
import { Pagination } from 'src/app/_types/driver.types';
import { LoaderService } from '../../services/loader.service';

interface IHandleOptions {
  record: any;
  selectedOption: string;
}
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent {
  @Input() dataToRender: any = [];
  @Input() displayedColumnKeys: string[] = [];
  @Output() pageChange$: EventEmitter<any> = new EventEmitter();
  @Output() handleSort$: EventEmitter<any> = new EventEmitter();
  @Output() recordEdit$: EventEmitter<any> = new EventEmitter();
  @Output() handleOption$: EventEmitter<IHandleOptions> =
    new EventEmitter<IHandleOptions>();
  @Output() recordView$: EventEmitter<any> = new EventEmitter();
  @Output() getActionsList$: EventEmitter<any> = new EventEmitter();

  viewIcon = '/assets/images/eye-black.svg';
  deleteIcon = '/assets/images/delete.svg';
  editIcon = '/assets/images/edit-color.svg';
  dataSource: any = [];
  paginationObj: Pagination = {
    currentPage: 0,
    pageSize: 10,
    itemCount: 0,
  };
  sortableColumnArray: SortingOption[] = [];
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  pageSizeOptions: number[] = [10, 20];
  @Input() DateColumns: string[] = [];
  @Input() ActionOptionsList: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  @Input() ShowActionIcons: string[] = [];
  @Input() SortableColumn: string[] = [];
  @Input() tableFieldList: any[] = [];
  @Input() ObjectColumn: any = {};
  @Input() StatusInChipColumn: string[] = [];
  public ObjectColumnToRender: string[] = [];
  @Input() isInvoiceList: boolean = false;

  constructor(private _LoaderService: LoaderService) {}

  handlePageSizeChange(e: PageEvent) {
    if (
      this.paginationObj.currentPage != e.pageIndex ||
      this.paginationObj.pageSize != e.pageSize
    ) {
      this._LoaderService.setState(true);
      this.paginationObj = {
        ...this.paginationObj,
        currentPage: e.pageIndex,
        pageSize: e.pageSize,
      };
      this.pageChange$.emit(this.paginationObj);
    }
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(
      this.isInvoiceList ? this.dataToRender.invoices : this.dataToRender.data
    );

    this.paginationObj = {
      ...this.paginationObj,
      itemCount: this.dataToRender?.totalCount,
      pageCount: this.dataToRender?.totalPages,
      currentPage: this.dataToRender?.currentPage - 1,
    };
  }

  initializeDataSource() {
    if (this.dataToRender) {
      this.dataSource = new MatTableDataSource(
        this.isInvoiceList ? this.dataToRender.invoices : this.dataToRender.data
      );
      this.dataSource.sort = this.sort;
      this.paginationObj = {
        ...this.paginationObj,
        itemCount: this.dataToRender?.totalCount,
        pageCount: this.dataToRender?.totalPages,
        currentPage: this.dataToRender?.currentPage - 1,
      };
    } else {
      this.dataSource = new MatTableDataSource<any>([]); // Initialize with empty array if data is null
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnInit() {
    this.initializeDataSource();
    this.ObjectColumnToRender = Object.keys(this.ObjectColumn);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
    // const existingIndex = this.sortableColumnArray.findIndex(
    //   (column: any) => column.field === this.sort.active
    // );
    //     if (existingIndex !== -1) {
    //   // If the field already exists, update the existing object
    //   this.sortableColumnArray[existingIndex] = {
    //     field: this.sort.active,
    //     order: this.sort.direction.toLocaleUpperCase(),
    //   };
    // } else {
    //   // If the field does not exist, insert the new object
    //   this.sortableColumnArray.push({
    //     field: this.sort.active,
    //     order: this.sort.direction.toLocaleUpperCase(),
    //   });
    // }

    this.sortableColumnArray = [
      {
        field: this.sort.active,
        order: this.sort.direction.toLocaleUpperCase(),
      },
    ];

    this.handleSort$.emit(this.sortableColumnArray);
    });
  }

  handleDetailRecord(record: any) {
    this.recordEdit$.emit(record);
  }

  handleView(record: any) {
    if (record.id) {
      this.recordView$.emit(record.id);
    } else {
      this.recordView$.emit(record);
    }
  }
  handleOptionClick(record: any, selectedOption: string) {
    this.handleOption$.emit({ record, selectedOption });
  }

  getActionOptionsList(record: any) {
    this.getActionsList$.emit(record);
  }
}
