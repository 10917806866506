import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PatternKey, patterns } from 'src/app/validators/pattern_validation';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  fieldName: string = '';
  constructor() {}

  getErrorMessage(controlName: string, form: FormGroup): string {
    const control = form.get(controlName);
    if (!control) return '';

    if (control.errors) {
      if (controlName.includes('_')) {
        this.fieldName = controlName.split('_').join(' ').toLowerCase();
      } else {
        //split camel case fieldNames
        this.fieldName = controlName
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .toLocaleLowerCase();
      }
      for (const errorKey of Object.keys(control.errors)) {
        switch (errorKey) {
          case 'required':
            //for fields which have diff error message than their field name
            const customFields: { [key: string]: string } = {
              postcode: 'Enter Postcode',
              address1: 'Enter address line 1',
              address2: 'Enter address line 2',
            };
            return (
              customFields[this.fieldName] ||
              (this.fieldName
                ? `Enter ${this.fieldName}`
                : `Enter ${controlName.toLowerCase()}`)
            );
          case 'pattern':
            const key = controlName.toUpperCase() as PatternKey;
            return patterns[key] ? patterns[key]?.message : '';
          case 'mismatch':
            return `Password doesn't match`;
          case 'notUnique':
            return `${
              this.fieldName ? this.fieldName : controlName
            } already exists`;
          case 'preExistInOtherForm':
            return `${
              this.fieldName ? this.fieldName : controlName
            } already exists in another form`;
          case 'preExist':
            return `${
              this.fieldName ? this.fieldName : controlName
            } matches old password`;
          case 'matDatepickerMin':
            return `${
              this.fieldName ? this.fieldName : controlName
            } must be today or later.`;
          // Add more cases as needed for other error types
          default:
            return '';
        }
      }
    }
    return '';
  }
}
