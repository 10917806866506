<div class="driverCount-wrapper">
    <div class="driver-count" *ngFor="let count of statsCount; let i = index">
        <div class="d-flex tab-wrapper">
            <span class="count-title">{{ count.title }}</span>
            <div *ngIf="isAdmin">
                <img src="assets/images/icons/lucide_info.svg" alt="i-icon" class="info-icon"
                    (mouseenter)="showTooltipIndex = i" (mouseleave)="showTooltipIndex = -1" />
                <div class="tooltip" *ngIf="showTooltipIndex === i">
                    <div class="d-flex tooltip-wrapper">
                        <div class="d-flex tooltip-inner">
                            <span class="tooltip-title">Active Drivers</span>
                            <span class="tooltip-data">{{count.activeDrivers}}</span>
                        </div>
                        <div class="d-flex tooltip-inner">
                            <span class="tooltip-title">Inactive Drivers</span>
                            <span class="tooltip-data">{{count.inActiveDrivers}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="count-value">{{count.value}}</span>
    </div>
</div>