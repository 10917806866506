<div class="table-wrapper" *ngIf="dataSource">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- Header row -->
      <ng-container *ngFor="let column of columnHeaders" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="icon-header">
          <div class="icon-wrapper">
            {{ column.split("_").join(" ") | uppercase }}
            <!-- <mat-icon *ngIf="isSortable(column)">swap_vert</mat-icon> -->
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="column !== 'action'; else ActionOptionsList">
            <ng-container *ngIf="
                column === 'name' || column === 'organisation_name' || column === 'organization_name';
                else noneName
              ">
              <ng-container *ngIf="column === 'name'; else OrgName">
                <strong>{{ element.first_name }} {{ element.last_name }}</strong>
              </ng-container>
              <ng-template #OrgName>
                <strong>{{ element[column] }}</strong>
              </ng-template>
            </ng-container>
            <ng-template #noneName>
              <ng-container *ngIf="
                  column === 'email' || column === 'telephone_number';
                  else noneIcon
                ">
                <div class="icon-wrapper icon-cell">
                  <ng-container *ngIf="column === 'email'; else phoneColumn">
                    <img src="assets/images/icons/mail.svg" />{{ element[column] | lowercase}}
                  </ng-container>
                  <ng-template #phoneColumn>
                    <img src="assets/images/icons/phone.svg" />+44 {{ element[column] }}
                  </ng-template>
                </div>
              </ng-container>
              <ng-template #noneIcon>
                <ng-container *ngIf="
                    column === 'driver_status' ||
                      column === 'subscription_status';
                    else nonStatus
                  ">
                  <mat-chip-option class="status" [ngClass]="{
                      'status-green': element.driver_status === 'active',
                      'status-red': element.driver_status === 'in_active'
                    }">
                    {{ element.driver_status.split("_").join("") | titlecase }}
                  </mat-chip-option>
                </ng-container>
                <ng-template #nonStatus>
                  <ng-container *ngIf="
                      column === 'created_at' ||
                        column === 'expiry_date' ||
                        column === 'deleted_on';
                      else nonDate
                    ">
                    {{ element[column] | date : "dd MMM yyyy" }}
                  </ng-container>
                  <ng-template #nonDate>
                    {{ element[column] }}
                  </ng-template>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-template #ActionOptionsList>
            <div class="icon-actions">
              <button mat-icon-button type="button" class="btn btn-circle btn-sm" (click)="onDriverDelete(element)"><img
                  src="/assets/images/icons/delete-form.svg" alt="delete-form">
              </button>
            </div>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
      <tr mat-row *matRowDef="let element; columns: columnHeaders"></tr>
    </table>
  </div>
  <div class="no-data-message" *ngIf="!dataSource || dataSource.data.length === 0">
    No records found
  </div>
  <!-- Pagination -->
  <mat-paginator #paginator appPaginator (page)="handlePageSizeChange($event)"
    [appCustomLength]="paginationObj.itemCount" [appCustomPageSize]="paginationObj.pageSize"
    [length]="paginationObj.itemCount" [pageSize]="paginationObj.pageSize" [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="paginationObj.currentPage" aria-label="Select page" showFirstLastButtons>
  </mat-paginator>
</div>
