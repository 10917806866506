import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IChangePswd, IResponse } from '../_types/common.types';
import { UserData } from '../_types/corporator.types';
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}
  getUserProfile(isAdmin: boolean, userId?: number): Observable<any> {
    const url = isAdmin
      ? `${apiConfig.admin}/view`
      : `${apiConfig.corporate}/${userId}`;
    return this.http.get<any>(url);
  }

  updateUserProfile(
    userId: number,
    data: any,
    isAdmin: boolean
  ): Observable<any> {
    return this.http.patch<IResponse>(
      isAdmin
        ? `${apiConfig.admin}/update`
        : `${apiConfig.corporate}/${userId}`,
      data
    );
  }

  changePassword(data: any): Observable<IChangePswd> {
    return this.http.post<IChangePswd>(
      `${apiConfig.auth}/change-password`,
      data
    );
  }

  addOfflineCorporate(data: any): Observable<UserData> {
    return this.http.post<UserData>(
      `${environment.apiBaseUrl}auth/corporator/create`,
      data
    );
  }
  editOrganization(data: any, userId: number): Observable<UserData> {
    return this.http.patch<UserData>(
      apiConfig.corporate + `/${userId}`,
      data
    );
  }
}


// const url = appConfig.corporator+ `/${id}`;
//         return this._ApiService.patchRequest<any>(url, data);
