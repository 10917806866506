<footer>
  <div class="footer-wrapper">
    <div>
      <p>@ All Rights Reserved. Delmate Limited 2024</p>
    </div>
    <ul>
      <li><a href="https://delm8.com/help-center-2/" target="_blank">Help Center</a></li>
      <li><a href="https://delm8.com/terms-and-conditions/" target="_blank">Terms and Conditions</a></li>
      <li><a href="https://delm8.com/privacy/" target="_blank">Privacy</a></li>
    </ul>
  </div>
</footer>
