import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

interface IConfig {
  panelClass: string[] | string;
  verticalPosition?: any;
  horizontalPosition?: any;
  duration?: number | 2000;
}

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(public snackbar: MatSnackBar, private _zone: NgZone) {}

  viewResponse(message: string | string[], action: string, settings: IConfig) {
    const config = new MatSnackBarConfig();
    config.panelClass = settings.panelClass;
    if (settings.verticalPosition == null || undefined) {
      settings.verticalPosition = 'bottom';
    }
    config.verticalPosition = settings.verticalPosition;
    if (settings.horizontalPosition == null || undefined) {
      settings.horizontalPosition = 'center';
    }
    config.horizontalPosition = settings.horizontalPosition;
    config.duration = settings.duration;

    this._zone.run(() => {
      if (Array.isArray(message) && message.length > 0) {
        message.forEach((element: string) => {
          return this.snackbar.open(element, action, config);
        });
      } else {
        this.snackbar.open(message as string, action, config);
      }
    });
  }


  showSuccess(message: string) {
    if (message) {
      const snackbarConfig = {
        panelClass: 'snackbar-sucess',
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000,
      };
      this.viewResponse(message, 'Dismiss', snackbarConfig);
    }
  }

  showError(errorStatus: any,horizontalPosition:string = 'right' ) {
    if (errorStatus) {
      const snackbarConfig = {
        panelClass: 'snackbar-error',
        verticalPosition: 'top',
        horizontalPosition: horizontalPosition,
        duration: 5000,
      };
      this.viewResponse(errorStatus, 'Dismiss', snackbarConfig);
    }
  }

  showWarning(warningStatus: any) {
    if (warningStatus) {
      const snackbarConfig = {
        panelClass: ['warning-snackbar', 'mb2-snackbar'],
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 5000,
      };
      this.viewResponse(warningStatus.message, 'Dismiss', snackbarConfig);
    }
  }
}
