import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
  minDate = new Date();
  range: FormGroup
  @Input() label: string = '';
  @Input() type: string = '';
  @Input() date: any = new FormControl<Date | null>(null);
  @Input() errorMessage: string = '';
  @Output() dateRangeChange = new EventEmitter<{ start: Date | null, end: Date | null }>();
  @Input() dateRangeTooltipMsg: string = '';//passing to table-control

  constructor(private _DateAdapter: DateAdapter<Date>,
    private _FormBuilder: FormBuilder) {
    this._DateAdapter.setLocale('en-GB'); //dd/MM/yyyy

    this.range = this._FormBuilder.group({
      start: [null],
      end: [null]
    });
  }

  ngOnInit(){
    this.range.valueChanges.subscribe(value => {
      if (value.start && value.end && value.start <= value.end) {
        this.dateRangeChange.emit(this.range.value);
      }
    });
  }
  clearDate() {
    this.range.reset();
    this.dateRangeChange.emit(this.range.value);
  }

}
