import {
  default_status,
  driver_status,
  plan_duration,
  subscription_status,
} from '../constant/db.constant';
import { ValidationRule } from '../shared/services/csv-to-json-convertor.service';
import { patterns } from '../validators/pattern_validation';
import { ICorporatorResponse, UserData } from './corporator.types';

export interface IDrivers {
  id: number;
  corporator: ICorporatorResponse;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  telephone_number?: string;
  driver_status: driver_status;
  is_email_verified: boolean;
  is_telephone_verified: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  status: default_status;
}

export interface DriverStatusPayload {
  driverId: number;
  status: driver_status;
  updatedPlanDuration?: plan_duration;
}

export interface DriverPaymentIntentResponse {
  data: PaymentIntent;
  message: string;
  success: boolean;
}

export interface PaymentIntent {
  clientSecret: string;
  qunatity: number;
  tax: number;
  perDriverPrice: number;
  daysRemaining: number;
  planDuration: string;
  amount: number;
  transactionId: string;
  vatAmount: number;
  subTotal: number;
}

export interface DriverCount {
  total: string;
  active: string;
  inactive: string;
  number_of_drivers: string | number;
}

export interface DriverList {
  id: number;
  created_at: Date;
  email: string;
  first_name: string;
  last_name: string;
  telephone_number: string;
  driver_status: string;
  is_email_verified: boolean;
  is_telephone_verified: boolean;
}

export interface Pagination {
  itemCount: number;
  pageCount?: number;
  currentPage: number;
  pageSize: number;
}

export interface IDriver {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  status: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  telephone_number: string;
  driver_status: string;
  is_email_verified: boolean;
  is_telephone_verified: boolean;
  expiry_date: Date;
}

export interface AddNewDriverListObject {
  data: AddNewDriver[];
  totalCount?: number;
  totalPages?: number;
  currentPage?: number;
}

export interface AddNewDriver {
  first_name: string;
  last_name: string;
  email: string;
  telephone_number: string;
}

export interface IDriverStatusPayload {
  driverId: string;
  status: string;
  updatedPlanDuration?: string;
}

export const DriverListOptions = [
  { key: 'edit', value: 'Edit' },
  { key: 'active', value: 'Activate' },
  { key: 'in_active', value: 'Deactivate' },
  { key: 'leaveCompany', value: 'Delete' },
];

export const DriverStatusOptions = [
  { key: 'all', value: 'All' },
  { key: 'active', value: 'Active' },
  { key: 'in_active', value: 'Inactive' },
];

export const setAlertMessage = (
  status: string,
  subscriptionData: UserData
): string => {
  let message: string =
    'Are you sure you want to update the status of the driver ?'; // Default message
  if (status === 'in_active') {
    message = 'Are you sure you want to Deactivate this driver ?';
  } else if (status === 'active') {
    if (subscriptionData.payment_mode === 'offline') {
      message = 'Are you sure you want to activate this driver ?';
    }
    //subscription status = expired/cancelled
    else if (
      subscriptionData.subscription?.subscription_status !==
        subscription_status.ACTIVE &&
      subscriptionData.subscription?.subscription_status !==
        subscription_status.TRIAL
    ) {
      message =
        'Your subscription has been ' +
        subscriptionData?.subscription?.subscription_status +
        '. Activating this driver will create a new subscription, are you sure you want to activate this driver?';
    }
      else if (subscriptionData?.isUserInGrace) {
        message =
          'Your subscription has been ' +
          subscriptionData.subscription.subscription_status +
          ', currently you are in grace period. Activating this driver will create a new subscription and will include all your previous active drivers amount, are you sure you want to activate this driver?';
      } else {
        //subscription status = active
        message =
          'Are you sure you want to activate this driver? Please note that if this driver is not covered in your current subscription you may incur additional charges.';
      }
  }
  return message;
};

export const RequiredFields: ValidationRule[] = [
  {
    field: 'First Name',
    validator: (value: any) => !!value, // Required field
    errorMessage: 'First name is required',
  },
  {
    field: 'Last Name',
    validator: (value: any) => !!value, // Required field
    errorMessage: 'Last name is required',
  },
  {
    field: 'Email',
    validator: (value: any) => !!value, // Required field
    errorMessage: 'Email is required',
  },
  {
    field: 'Phone Number',
    validator: (value: any) => !!value, // Required field
    errorMessage: 'Phone number is required',
  },
];

export const ValidationRules: ValidationRule[] = [
  {
    field: 'Email',
    validator: (value: any) => patterns.EMAIL.regex.test(value), // Email regex validation
    errorMessage: patterns.EMAIL.message,
  },
  {
    field: 'Phone Number',
    validator: (value: any) =>
      patterns.TELEPHONE_NUMBER.regex.test(value), // Phone number regex validation
    errorMessage: patterns.TELEPHONE_NUMBER.message,
  },
];


export const FieldMapping = {
  'First Name': 'first_name',
  'Last Name': 'last_name',
  'Phone Number': 'telephone_number',
  Email: 'email',
};

export const bulkUploadSampleArray = [
  {
      "First Name": "",
      "Last Name": "",
      "Email": "",
      "Phone Number": "",
      "Note: Please do not add the leading '0' and/or '+44' to the number, For example, 07123456789 will become 7123456789": ""
  },
  {
    "First Name": "John",
    "Last Name": "Doe",
    "Email": "johndoe@delm8.com",
    "Phone Number": "7123456789",
    "This is a sample record, please delete.": ""
  }
]
