<!-- confirmation modal -->
<div *ngIf="data.type === 'confirmation'">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div
    mat-dialog-actions
    class="dialog-actions"
    *ngIf="data.confirmationType === 'deleteDriver'; else showYesNoButtons"
  >
    <button mat-button (click)="onClose(true)">Delete</button>
    <button mat-button color="warn" (click)="onClose(false)">Keep</button>
  </div>
  <ng-template #showYesNoButtons>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-button (click)="onClose(false)">No</button>
      <button mat-button color="warn" (click)="onClose(true)">Yes</button>
    </div>
  </ng-template>
</div>

<!-- change password modal -->
<div *ngIf="data.type === 'changePassword'" class="change-password-dialog">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button
      mat-icon-button
      (click)="onClose(false)"
      *ngIf="!data.isFirstTimeLogin"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="changePassword">
      <app-input
        label="Old Password"
        type="password"
        [control]="changePassword.get('oldPassword')"
        [errorMessage]="getErrorMessage('oldPassword', 'changePassword')"
        *ngIf="!data.isFirstTimeLogin"
      ></app-input>
      <app-input
        label="New Password"
        type="password"
        [control]="changePassword.get('newPassword')"
        [errorMessage]="getErrorMessage('newPassword', 'changePassword')"
      ></app-input>
      <app-input
        label="Confirm New Password"
        type="password"
        [control]="changePassword.get('confirmPassword')"
        [errorMessage]="getErrorMessage('confirmPassword', 'changePassword')"
      ></app-input>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      mat-button
      (click)="changePswd(data.isFirstTimeLogin)"
      [disabled]="!changePassword.valid"
      [ngClass]="{ disabled: !changePassword.valid }"
    >
      {{ data.isFirstTimeLogin ? "Save" : "Reset" }}
    </button>
    <button
      mat-button
      color="warn"
      (click)="onClose(false)"
      *ngIf="!data.isFirstTimeLogin"
    >
      Cancel
    </button>
  </div>
</div>

<!-- driver calculation modal -->
<div *ngIf="data.type === 'driverCalculations'">
  <div class="main-dialog-wrapper">
    <h2 mat-dialog-title>Driver calculation</h2>
    <div class="internal-dialog-wrapper">
      <div class="dialog-header">
        <h2 mat-dialog-title>{{ data.title }}</h2>
      </div>
      <div class="calculation-wrapper">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="label">No. of driver(s)</div>
            <div>
              {{
                driverDataCalculations.quantity ||
                  driverDataCalculations.qunatity
              }}
            </div>
          </li>
          <li class="list-group-item">
            <div class="label">Cost per driver</div>
            <div>£{{ driverDataCalculations.perDriverPrice }}</div>
          </li>
          <li class="list-group-item">
            <div class="label">Days remaining</div>
            <div>{{ driverDataCalculations.daysRemaining }} days</div>
          </li>
          <li class="list-group-item">
            <div class="label">Vat</div>
            <div>{{ driverDataCalculations.tax }}%</div>
          </li>
          <li class="list-group-item">
            <div class="label">Total</div>
            <div>£{{ driverDataCalculations.amount }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="handleDriverCalculationButtonClick(true)">
      Pay Now
    </button>
    <button
      mat-button
      color="warn"
      (click)="handleDriverCalculationButtonClick(false)"
    >
      {{ this.data?.showPayLater ? "Pay Later" : "Cancel" }}
    </button>
  </div>
</div>

<!-- payNow cardDetails -->
<div *ngIf="data.type === 'cardDetails'">
  <div class="card-detail-wrapper">
    <div class="dialog-header">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <!-- <button mat-icon-button (click)="payLaterOrOnClose()">
        <mat-icon>close</mat-icon>
      </button> -->
    </div>
    <div mat-dialog-content>
      <form [formGroup]="paymentForm" name="form">
        <div class="row">
          <div>
            <app-input
              type="text"
              label="Name on Card"
              [control]="paymentForm.get('name_on_card')"
              [errorMessage]="getErrorMessage('name_on_card', 'cardDetails')"
            ></app-input>
          </div>
        </div>
        <div
          class="form-group"
          [formGroup]="paymentForm"
          ngxStripeCardGroup
          [elementsOptions]="elementsOptions"
        >
          <ngx-stripe-card-number
            [options]="cardOptions"
            (change)="onChange($event, 'cardNumber')"
          ></ngx-stripe-card-number>
          <div id="card-number-errors" class="error-message card"></div>
          <div class="d-flex justify-content-between">
            <div class="w-50">
              <ngx-stripe-card-expiry
                #cardNumberRef
                [options]="cardOptions"
                (change)="onChange($event, 'cardExpiry')"
              ></ngx-stripe-card-expiry>
              <div class="error-message card" id="card-expiry-errors"></div>
            </div>
            <div class="w-50">
              <ngx-stripe-card-cvc
                [options]="cardOptions"
                (change)="onChange($event, 'cardCvc')"
              ></ngx-stripe-card-cvc>
              <div class="error-message card" id="card-cvc-errors"></div>
            </div>
          </div>
        </div>
      </form>
      <mat-checkbox
        *ngIf="this.data?.isCardSaved"
        class="check"
        color="primary"
        class="save-card"
        [(ngModel)]="isCardSaved"
      >
        Saved this card for future reference.
      </mat-checkbox>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button
        mat-button
        [disabled]="
          !paymentForm.valid ||
          !cardNumberValid ||
          !cardExpiryValid ||
          !cardCvcValid
        "
        (click)="payNow()"
        label="Pay now"
      >
        {{ this.data?.isCardSaved ? "Pay Now" : "Add Card" }}
      </button>
      <button mat-button color="warn" (click)="payLaterOrOnClose()">
        {{ this.data?.showPayLater ? "Pay Later" : "Cancel" }}
      </button>
    </div>
  </div>
</div>

<!-- select card -->
<div *ngIf="data.type === 'selectCard'" class="select-card-wrapper">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button
      mat-icon-button
      (click)="onClose(false)"
      *ngIf="data?.showCancelIcon"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p>Please choose a payment card</p>
    <div class="card-wrapper">
      <div class="card-header d-flex">
        <div>Card Number</div>
        <div class="text-center">Brand</div>
        <div class="text-center">Created ON</div>
        <div class="text-center">Select</div>
      </div>
      <div
        class="card-detail d-flex"
        *ngFor="let card of data?.cardList"
        (click)="selectCard(card)"
      >
        <div>{{ card?.last4 }}</div>
        <div class="text-center">{{ card?.brand }}</div>
        <div class="text-center">
          {{ card?.created | date : "dd MMM yyyy" }}
        </div>
        <div class="text-center">
          <input
            type="radio"
            [id]="card?.id"
            [name]="'selectedCard'"
            [checked]="card?.isDefault"
          />
        </div>
      </div>
      <div
        *ngIf="!data?.cardList?.length"
        class="d-flex justify-content-center"
      >
        No cards found.
      </div>
    </div>
    <div class="button-container">
      <div>
        <div class="driver-calculation">
          <div class="driver-calculation-title">Sub Total:</div>
          <div>
            £{{
              (data.driverAmountSummary.amount
                ? data.driverAmountSummary.amount
                : "0"
              ) | number : "1.2-2"
            }}
          </div>
        </div>
        <div class="driver-calculation">
          <div class="driver-calculation-title">VAT:</div>
          <div>
            £{{
              (data.driverAmountSummary.vat
                ? data.driverAmountSummary.vat
                : "0"
              ) | number : "1.2-2"
            }}
          </div>
        </div>
        <div class="driver-calculation">
          <div class="driver-calculation-title">Total Amount:</div>
          <div>
            £{{
              (data.driverAmountSummary.totalAmount
                ? data.driverAmountSummary.totalAmount
                : "0"
              ) | number : "1.2-2"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      type="button"
      mat-flat-button
      class="primary-btn me-4"
      (click)="handleCardButtonClick('add')"
    >
      Add new card
    </button>
    <button
      type="button"
      mat-flat-button
      class="primary-btn me-4"
      [disabled]="!selectedCard"
      (click)="handleCardButtonClick('pay')"
    >
      Pay now
    </button>
    <button
      *ngIf="!data.showCancelIcon"
      type="button"
      mat-flat-button
      class="primary-btn me-4"
      [disabled]="!selectedCard"
      (click)="onClose(false)"
    >
      Pay Later
    </button>
  </div>
</div>

<!-- Bulk upload -->
<div *ngIf="data.type === 'bulkUpload'" class="bulk-upload-wrapper">
  <div class="dialog-header">
    <div class="d-flex justify-content-center align-items-center">
      <h2 mat-dialog-title>
        {{ data.title }}
      </h2>
      <img
        src="assets/images/icons/lucide_info.svg"
        alt="i-icon"
        class="info-icon"
        (mouseenter)="showBulkUpoadTooltip = true"
        (mouseleave)="showBulkUpoadTooltip = false"
      />
      <div class="tooltip" *ngIf="showBulkUpoadTooltip">
        <div class="d-flex tooltip-wrapper">
          <div class="d-flex tooltip-inner">
            <h2 class="tooltip-title">How to upload drivers from a file:</h2>
            <ul class="tooltip-data">
              <li>1) Download the file template.</li>
              <li>
                2) See the example record in the file and then delete it. That
                record is there to guide you on how to fill this form.
              </li>
              <li>
                3) Fill all the columns. Please note all columns are required
                and should be filled with data in the correct format as shown in
                the example record.
              </li>
              <li>
                4) You can only have upto 100 drivers per file. To load more,
                please add their details in a separate file.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form autocomplete="off">
      <div class="mb-3">
        <div *ngIf="!bulkUploadSuccessfull || showIgnoreUploadButton">
          <div class="form-label">
            <span
              >Download the <strong>“Driver Upload Template”</strong> then fill
              it in.</span
            >
            <button
              type="button"
              mat-flat-button
              class="primary-btn p-2 px-4 download-btn"
              (click)="downloadSampleCSVFile()"
            >
              <img src="/assets/images/icons/download-white.svg" /> Drivers
              Template
            </button>
          </div>
          <p class="note">
            Note: You can only have upto 100 drivers per file and file must be
            in csv format
          </p>
        </div>
        <input
          *ngIf="!bulkUploadSuccessfull"
          class="form-control"
          type="file"
          #bulkUploadFile
          id="formFile"
          accept=".csv"
          [disabled]="bulkUploadSuccessfull"
          (change)="onFileChange($event)"
          (click)="onFileClick($event)"
        />
        <p
          *ngIf="
            fileTypeCsv
              ? this.bulkDriversList
                ? checkErrorInCsv()
                : csvLimitExceed
                ? true
                : false
              : true
          "
          class="csv-error-message"
          [innerHTML]="csvErrorMessage"
        ></p>
      </div>
    </form>
    <ng-container *ngIf="!bulkUploadSuccessfull; else correctData">
      <div
        class="record-table"
        *ngIf="bulkDriversList && bulkDriversList.invalidRows?.length"
      >
        <div class="invalidRecords-action">
          <h3>{{ bulkDriversList.invalidRows.length }} Invalid Record(s)</h3>
          <button
            type="button"
            mat-flat-button
            class="primary-btn p-2 px-4"
            *ngIf="showDownloadReportBtn"
            (click)="downloadErrorReport()"
          >
            Download Report
          </button>
        </div>
        <div class="records-wrapper">
          <div class="records-header d-flex">
            <div>First Name</div>
            <div class="text-center">Last Name</div>
            <div class="text-center">Email</div>
            <div class="text-center">Phone Number</div>
            <div class="text-center">Error Details</div>
          </div>
          <div
            class="records-detail d-flex"
            *ngFor="let record of bulkDriversList.invalidRows"
          >
            <div>{{ record?.row["First Name"] }}</div>
            <div class="text-center">{{ record?.row["Last Name"] }}</div>
            <div class="text-center">{{ record?.row["Email"] }}</div>
            <div class="text-center">{{ record?.row["Phone Number"] }}</div>
            <div class="text-center">{{ record?.errors?.join(", ") }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #correctData>
      <div class="successDataMsg">
        <h3>File Name: {{fileName}} </h3>
        <mat-icon>check_circle</mat-icon>
        Driver(s) added successfully.
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="dialog-actions-inner" *ngIf="!bulkUploadSuccessfull">
      <button
        *ngIf="showIgnoreUploadButton"
        type="button"
        mat-flat-button
        class="gray-btn p-2 px-4 me-3"
        [disabled]="this.bulkDriversList ? checkErrorInCsv() : true"
        [ngClass]="{
          disabled: this.bulkDriversList ? checkErrorInCsv() : true
        }"
        (click)="uploadBulkDriver()"
      >
        {{
          this.bulkDriversList
            ? this.bulkDriversList.invalidRows.length
              ? "Ignore & Upload"
              : "Upload"
            : "Upload"
        }}
      </button>
      <img
        src="assets/images/icons/lucide_info.svg"
        alt="i-icon"
        class="info-icon"
        *ngIf="
          showIgnoreUploadButton
            ? this.bulkDriversList
              ? this.bulkDriversList.invalidRows.length
              : false
            : false
        "
        (mouseenter)="showIgnoreAndDwnldTooltip = true"
        (mouseleave)="showIgnoreAndDwnldTooltip = false"
      />
      <div class="tooltip" *ngIf="showIgnoreAndDwnldTooltip">
        <div class="d-flex tooltip-wrapper">
          <div class="d-flex tooltip-inner">
            <div class="tooltip-data">
              This will ignore the failed record(s) and load the rest.
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      mat-flat-button
      class="primary-btn p-2 px-4"
      (click)="onClose(false)"
    >
      {{
        bulkUploadSuccessfull
          ? "Finish"
          : !showIgnoreUploadButton
          ? "Close"
          : "Cancel"
      }}
    </button>
  </div>
</div>

<!-- Driver allocation exceed modal -->
<div *ngIf="data.type === 'driverAllocationExceed'">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="openLinkInNewPage()">Contact Us</button>
    <button mat-button color="warn" (click)="onClose(true)">Continue</button>
  </div>
</div>

<!-- Add new subscription -->
<div *ngIf="data.type === 'addNewSubscription'">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="handleSubscriptionCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div class="subscription-plan-wrapper" [formGroup]="planForm">
      <div class="plan-list pt-3">
        <div
          class="col-md-4 position-relative w-5 plan-block"
          *ngFor="let ele of data.planList"
        >
          <input
            type="radio"
            name="planId"
            formControlName="planId"
            id="{{ ele.duration }}"
            value="{{ ele.duration }}"
          />
          <div class="sub-header">
            <h2 class="plan-type">DELM8 {{ ele.plan_name }}</h2>
            <p>
              £{{ ele.per_driver_price }}/ Per user per
              {{ ele.duration === "monthly" ? "month" : "year" }}
            </p>
          </div>
        </div>
      </div>

      <div class="button-wrapper mt-5 mb-3">
        <button
          type="button"
          mat-flat-button
          class="border-btn"
          (click)="handleSubscriptionCancel()"
        >
          Cancel
        </button>
        <button
          type="button"
          mat-flat-button
          disabled="{{ !planForm.valid }}"
          class="primary-btn me-4"
          (click)="addNewSubscription()"
        >
          Choose
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Edit plan details -->
<div *ngIf="data.type === 'editPlanDetails'">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div>
      <form [formGroup]="editPlan">
        <app-input
          label="Plan Name"
          placeholder="Plan Name"
          type="text"
          [control]="editPlan.get('plan_name')"
          [errorMessage]="getErrorMessage('plan_name', 'editPlan')"
          class="w-50"
        ></app-input>
      </form>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onClose(false)">Cancel</button>
    <button
      mat-button
      color="warn"
      [disabled]="!editPlan.valid"
      (click)="editPlanDetails(true, editPlan.get('plan_name')?.value)"
    >
      Update
    </button>
  </div>
</div>

<!-- update subscription / convert to offline  modal -->
<div *ngIf="data.type === 'subscriptionEditModal'" class="subscription-details">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="subscription-wrapper">
      <div>
        <div
          class="previous-form-wrapper"
          *ngIf="selectedOrganisationPaymentMode"
        >
          <form [formGroup]="previousSubForm">
            <div class="sub-title">Previous subscription details:</div>
            <!-- for previous subscription data -->
            <div class="sub-input">
              <div class="input-wrapper d-flex">
                <app-input
                  label="Start date"
                  placeholder="Start date"
                  type="text"
                  class="w-50"
                  [control]="previousSubForm.get('startDate')"
                ></app-input>
                <app-input
                  label="End date"
                  placeholder="End date"
                  type="text"
                  class="w-50"
                  [control]="previousSubForm.get('endDate')"
                ></app-input>
              </div>
              <div class="input-wrapper d-flex">
                <app-input
                  label="No. of Drivers"
                  placeholder="No. of Drivers"
                  type="text"
                  class="w-50"
                  [control]="previousSubForm.get('numberOfDrivers')"
                  maxlength="5"
                ></app-input>
                <app-input
                  label="Cost per driver"
                  placeholder="Cost per driver"
                  type="text"
                  class="w-50"
                  [control]="previousSubForm.get('cost_per_driver')"
                ></app-input>
              </div>
            </div>
          </form>
        </div>
        <div class="new-form-wrapper">
          <form [formGroup]="subForm">
            <div class="sub-title">
              {{
                selectedOrganisationPaymentMode
                  ? "New subscription details:"
                  : "New offline subscription"
              }}
            </div>
            <!-- for new subscription data -->
            <div class="sub-input">
              <div class="d-flex input-wrapper">
                <app-datepicker
                  label="Start date"
                  [date]="subForm.get('start_date')"
                  [errorMessage]="
                    getErrorMessage('start_date', 'subscriptionEditModal')
                  "
                  class="w-50"
                />
                <app-datepicker
                  label="End date"
                  [date]="subForm.get('end_date')"
                  [errorMessage]="
                    getErrorMessage('end_date', 'subscriptionEditModal')
                  "
                  class="w-50"
                />
              </div>
              <div class="d-flex input-wrapper">
                <app-input
                  label="No. of Drivers"
                  placeholder="No. of Drivers"
                  type="text"
                  class="w-50"
                  [control]="subForm.get('number_of_drivers')"
                  maxlength="5"
                  [errorMessage]="
                    getErrorMessage(
                      'number_of_drivers',
                      'subscriptionEditModal'
                    )
                  "
                  (input)="totalAmountCalculation()"
                  [isTooltipVisible]="true"
                  [tooltipMessage]="
                    'This is the total driver allowance for this company, and not just additional driver(s)'
                  "
                >
                </app-input>
                <app-input
                  label="Cost per driver"
                  placeholder="Cost per driver"
                  type="text"
                  class="w-50"
                  [control]="subForm.get('cost_per_driver')"
                  [errorMessage]="
                    getErrorMessage('cost_per_driver', 'subscriptionEditModal')
                  "
                  (input)="totalAmountCalculation()"
                  [isTooltipVisible]="true"
                  [tooltipMessage]="
                    'This is the full price for the entire period'
                  "
                ></app-input>
              </div>
            </div>
            <!-- for driver prorata calculations -->
            <div class="driver-calculation-wrapper">
              <div class="driver-calculation">
                <div class="driver-calculation-title">Sub Total:</div>
                <div>
                  £{{
                    (driverSubTotal ? driverSubTotal : "0") | number : "1.2-2"
                  }}
                </div>
              </div>
              <div class="driver-calculation">
                <div class="driver-calculation-title">VAT:</div>
                <div>
                  £{{ (vatPercent ? vatPercent : "0") | number : "1.2-2" }}
                </div>
              </div>
              <div class="driver-calculation">
                <div class="driver-calculation-title">Total Amount:</div>
                <div>
                  £{{ (totalAmount ? totalAmount : "0") | number : "1.2-2" }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      mat-button
      color="warn"
      (click)="updateSubscription(true, subForm.value)"
      [disabled]="!subForm.valid"
    >
      Update
    </button>
  </div>
</div>

<!-- To show informative Data (Ok button only) -->
<div *ngIf="data.type === 'informative'">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button color="warn" (click)="onClose(false)">Ok</button>
  </div>
</div>
