<div *ngIf="type !== 'range'" class="date-picker-wrapper">
  <!-- <label *ngIf="label">{{ label }}</label> -->
  <mat-form-field appearance="outline">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input matInput [matDatepicker]="datepicker" [formControl]="date" [min]="minDate" (click)="datepicker.open()" readonly/>
    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
  <mat-error *ngIf="date.invalid && date.touched" class="error-message">
    {{ errorMessage }}
  </mat-error>
</div>
<div *ngIf="type === 'range'" class="date-range-picker">
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date" (click)="picker.open()" readonly/>
    <input matEndDate formControlName="end" placeholder="End date" (click)="picker.open()" readonly/>
  </mat-date-range-input>
  <button mat-icon-button  [ngClass]="{'mat-info-icon': true ,'clear-icon': range.value.start || range.value.end}" (click)="range.value.start || range.value.end ? clearDate() : null">
    <ng-container *ngIf="range.value.start || range.value.end; else showTooltip">
      <mat-icon>clear</mat-icon>
    </ng-container>
    <ng-template #showTooltip>
      <img
      src="assets/images/icons/lucide_info.svg"
      alt="i-icon"
      class="info-icon"
      matTooltip="{{dateRangeTooltipMsg}}"
      matTooltipClass="custom-tooltip"
    />
    </ng-template>
  </button>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</div>
