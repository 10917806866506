import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

interface ValidatorFunction {
  (value: any): boolean;
}

export interface ValidationRule {
  field: string;
  validator: ValidatorFunction;
  errorMessage: string;
}

export interface ExcelProcessingResult {
  validRows: any[];
  invalidRows: { row: any; errors: string[] }[];
}

@Injectable({
  providedIn: 'root',
})
export class CsvToJsonConvertorService {
  constructor() {}
  async processExcelFile(
    file: File,
    fieldMapping: { [key: string]: string },
    validationRules: ValidationRule[],
    recordLimit: number = 101, // Default limit is 100 records
    uniqueField: string[] = ['Email', 'Phone Number'] // Add unique fields for duplicate check
  ): Promise<ExcelProcessingResult> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const binaryString: string = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(binaryString, {
          type: 'binary',
        });
        const sheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {
          raw: true,
          range: 'A1:Z' + (recordLimit + 5),
        });
        if (jsonData.length > recordLimit)
          reject('The number of rows in the excel file should not exceed 100');

        const result: ExcelProcessingResult = {
          validRows: [],
          invalidRows: [],
        };

        const seenRows = new Map<string, number>(); // To track duplicates by concatenating unique field values

        // Convert keys to desired JSON key names and perform validation
        jsonData.forEach((row) => {
          const convertedRow: any = {};
          const errors: string[] = [];
          validationRules.forEach((rule) => {
            const columnName = rule.field;

            const fieldName = fieldMapping[columnName];

            if (fieldName) {
              let value = row[columnName];
              // Check if the field represents a phone number
              if (fieldName === 'telephone_number') {
                value = String(value);
                // Add leading '+' if not present
                // value = value.startsWith('+') ? value : `+${value}`;
              }
              if (!value || !rule.validator(value)) {
                errors.push(rule.errorMessage || `Invalid ${fieldName}`);
              }
              convertedRow[fieldName] = String(value);
            }
          });

          // Check for duplicates based on individual unique fields
          uniqueField.forEach((field) => {
            const value = row[field];

            if (seenRows.has(`${field}:${value}`)) {
              const existingIndex = seenRows.get(`${field}:${value}`);
              if (existingIndex !== undefined) {
                errors.push(
                  `Duplicate ${field || fieldMapping[field]} found`
                );
              }
            } else {
              seenRows.set(`${field}:${value}`, result.invalidRows.length);
            }
          });
          if (errors.length === 0) {
            result.validRows.push(convertedRow);
          } else {
            result.invalidRows.push({ row, errors });
          }
        });
        resolve(result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  }
}
