import { Component, Input, OnInit } from '@angular/core';
import { UserData } from 'src/app/_types/corporator.types';
import { ROLES, payment_mode } from 'src/app/constant/db.constant';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-statistical-data',
  templateUrl: './statistical-data.component.html',
  styleUrls: ['./statistical-data.component.scss'],
})
export class StatisticalDataComponent implements OnInit {
  @Input() statsData!: any;
  statsCount: {
    title: string;
    value: number | string;
    activeDrivers?: string;
    inActiveDrivers?: string;
  }[] = [];
  @Input() nextInvoiceDate: string = '';
  @Input() view: string = '';
  showTooltipIndex: number = -1;
  isAdmin: boolean = false;
  userRole: any;
  userData!: UserData | any;

  constructor(
    private _SessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.userRole = this._SessionService.getSession('user_role') as ROLES;
    this.isAdmin = this.userRole === ROLES.ROLE_SUPER_ADMIN;
    //for getting payment mode for driver allowance tab
    !this.isAdmin
      ? (this.userData = this._SessionService.getSession('userData'))
      : null;
    this.userData ? (this.userData = JSON.parse(this.userData)) : '';

    if (this.statsData) {
      switch (this.view) {
        case 'invoice':
          this.statsCount = [
            { title: 'Active Drivers', value: Number(this.statsData.active) },
            {
              title: 'Next invoice due on',
              value: this.nextInvoiceDate,
            },
          ];
          break;
        case 'dashboard':
          this.statsCount = [
            { title: 'Total Drivers', value: Number(this.statsData.total) },
            { title: 'Active Drivers', value: Number(this.statsData.active) },
            {
              title: 'Inactive Drivers',
              value: Number(this.statsData.inactive),
            },
          ];
          break;
        case 'admin-dashboard':
          this.statsCount = [
            {
              title: 'Corporate Users',
              value: Number(this.statsData.totalCorporatorCount),
              activeDrivers: this.statsData.totalActiveDrivers,
              inActiveDrivers: this.statsData.totalInActiveDrivers,
            },
            {
              title: 'Online Organisations',
              value: Number(this.statsData.totalOnlineCorporate),
              activeDrivers: this.statsData.totalOnlineActiveDrivers,
              inActiveDrivers: this.statsData.totalOnlineInActiveDrivers,
            },
            {
              title: 'Offline Organisations',
              value: Number(this.statsData.totalOfflineCorporate),
              activeDrivers: this.statsData.totalOfflineActiveDrivers,
              inActiveDrivers: this.statsData.totalOfflineInActiveDrivers,
            },
          ];
          break;
        default:
          this.statsCount = [];
      }

      //append driver allowance for offline corporates
      if (
        this.view === 'dashboard' &&
        this.userData?.payment_mode === payment_mode.OFFLINE
      ) {
        this.statsCount.splice(1, 0, {
          title: 'Driver Allowance',
          value: Number(this.statsData.number_of_drivers),
        });
      }
    }
  }

  ngOnChanges() {
    if (this.statsData) {
      switch (this.view) {
        case 'invoice':
          this.statsCount = [
            { title: 'Active Drivers', value: Number(this.statsData.active) },
            {
              title: 'Next invoice due on',
              value: this.nextInvoiceDate,
            },
          ];
          break;
        case 'dashboard':
          this.statsCount = [
            { title: 'Total Drivers', value: Number(this.statsData.total) },
            { title: 'Active Drivers', value: Number(this.statsData.active) },
            {
              title: 'Inactive Drivers',
              value: Number(this.statsData.inactive),
            },
          ];
          break;
        case 'admin-dashboard':
          this.statsCount = [
            {
              title: 'Corporate Users',
              value: Number(this.statsData.totalCorporatorCount),
              activeDrivers: this.statsData.totalActiveDrivers,
              inActiveDrivers: this.statsData.totalInActiveDrivers,
            },
            {
              title: 'Online Organisations',
              value: Number(this.statsData.totalOnlineCorporate),
              activeDrivers: this.statsData.totalOnlineActiveDrivers,
              inActiveDrivers: this.statsData.totalOnlineInActiveDrivers,
            },
            {
              title: 'Offline Organisations',
              value: Number(this.statsData.totalOfflineCorporate),
              activeDrivers: this.statsData.totalOfflineActiveDrivers,
              inActiveDrivers: this.statsData.totalOfflineInActiveDrivers,
            },
          ];
          break;
        default:
          this.statsCount = [];
      }

      //append driver allowance for offline corporates
      if (
        this.view === 'dashboard' &&
        this.userData?.payment_mode === payment_mode.OFFLINE
      ) {
        this.statsCount.splice(1, 0, {
          title: 'Driver Allowance',
          value: Number(this.statsData.number_of_drivers),
        });
      }
    }
  }
}
