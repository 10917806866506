import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Pagination } from 'src/app/_types/driver.types';
import { LoaderService } from '../../services/loader.service';
import { AriaDescriber } from '@angular/cdk/a11y';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Output() driverId: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChange: EventEmitter<Pagination> =
    new EventEmitter<Pagination>();
  pageSizeOptions: number[] = [10, 20];
  selectedPageSize: number = 5;

  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() listingData!: any;
  dataSource!: MatTableDataSource<any>;
  userRole: string = 'ROLE_ADMIN';
  @Input() columnHeaders: string[] = [];

  paginationObj: Pagination = {
    currentPage: 1,
    pageSize: 10,
    itemCount: 0,
  };

  constructor(
    private _LoaderService: LoaderService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  initializeDataSource() {
    if (this.listingData) {
      this.dataSource = new MatTableDataSource<any>(this.listingData);
    } else {
      this.dataSource = new MatTableDataSource<any>([]); // Initialize with empty array if data is null
    }
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  ngOnInit(): void {
    this.initializeDataSource(); // Call method to initialize data source
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.initializeDataSource(); // Call method to initialize data source
  }

  isSortable(column: string): boolean {
    //for columns which have sort options
    return column === 'name' || column === 'email' || column === 'created_at';
  }
  handlePageSizeChange(event: PageEvent) {
    if (
      this.paginationObj.currentPage != event.pageIndex ||
      this.paginationObj.pageSize != event.pageSize
    ) {
      this.paginationObj = {
        ...this.paginationObj,
        currentPage: event.pageIndex,
        pageSize: event.pageSize,
      };
      this.pageSizeChange.emit(this.paginationObj);
    }
  }

  onDriverDelete(driver: any) {
    const index = this.listingData.indexOf(driver);
    this.driverId.emit(index);
  }
}
