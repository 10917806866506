import { ISidebarItem } from '../_types/common.types';

export const navigationPath = [
  {
    DASHBOARD: 'dashboard',
  },
];

const imgPath = 'assets/images/icons/';

export const corporateSidebarList: ISidebarItem[] = [
  {
    name: 'Dashboard',
    path: '/corporate/dashboard',
    icon: `${imgPath}dashboard-icon-disable.svg`,
    activeIcon: `${imgPath}dashboard-icon-enable.svg`,
  },
  {
    name: 'Drivers',
    path: '/corporate/driver',
    icon: `${imgPath}driver-icon-disable.svg`,
    activeIcon: `${imgPath}driver-icon-enable.svg`,
  },
  {
    name: 'Subscription',
    path: '/corporate/subscription',
    icon: `${imgPath}subscription-icon-disable.svg`,
    activeIcon: `${imgPath}subscription-icon-enable.svg`,
  },
  {
    name: 'Invoices',
    path: '/corporate/invoice',
    icon: `${imgPath}invoices-icon-disable.svg`,
    activeIcon: `${imgPath}invoices-icon-enable.svg`,
    disable: false,
  },
  {
    name: 'Saved Cards',
    path: '/corporate/saved-cards',
    icon: `${imgPath}savedCards-icon-disable.svg`,
    activeIcon: `${imgPath}savedCards-icon-enable.svg`,
    disable: false,
  },
  {
    name: 'Help & Support',
    path: '',
    icon: `${imgPath}help&Support-icon-disable.svg`,
    activeIcon: `${imgPath}help&Support-icon-enable.svg`,
    disable: false,
  },

  // { name: 'Settings', path: '/corporate/'}
];

export const superAdminSidebarList: ISidebarItem[] = [
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    icon: `${imgPath}dashboard-icon-disable.svg`,
    activeIcon: `${imgPath}dashboard-icon-enable.svg`,
    disable: false,
  },
  {
    name: 'Organisation',
    path: '/admin/organization',
    icon: `${imgPath}driver-icon-disable.svg`,
    activeIcon: `${imgPath}driver-icon-enable.svg`,
    disable: false,
  },
  {
    name: 'Subscription',
    path: '/admin/subscription',
    icon: `${imgPath}subscription-icon-disable.svg`,
    activeIcon: `${imgPath}subscription-icon-enable.svg`,
    disable: false,
  },
  {
    name: 'Invoices',
    path: '/admin/invoice',
    icon: `${imgPath}invoices-icon-disable.svg`,
    activeIcon: `${imgPath}invoices-icon-enable.svg`,
    // disable: true,
  },
  // { name: 'Missing Address', icon: 'help' }
];
