import { FormGroup } from '@angular/forms';

export type PatternKey = keyof typeof patterns;

export const patterns = {
  EMAIL: {
    regex:
      /^\w+([\.+-]?\w+)*@[a-zA-Z0-9]+(?:[-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,4}\s*$/,
    message: 'Enter a valid email.',
  },
  POSTCODE: {
    regex:
      /^\s*(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Za-z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Za-z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)\s*$/i,
    message: 'Enter a valid Postcode.',
  },
  TELEPHONE_NUMBER: {
    regex: /^(0[7][0-9]{9}|7[0-9]{9})$/,
    message: 'Enter a valid phone number.',
  },
  CORPORATE_TELEPHONE_NUMBER: {
    // regex: /^(\+44\s?7\d{9}|0\d{2,4}\s?\d{3,4}\s?\d{3,4}|7\d{9})$/,
    regex: /^(\+44\s?7\d{9}|0(7\d{9}|[1-6]\d{8,10})|7\d{9,10}|[1-6]\d{8,10})$/,
    message: 'Enter a valid phone number.',
  },
  COMPANY_NAME: {
    regex: /^.{0,50}$/,
    message:
      "Enter a valid organisation name up to 50 characters.",
  },
  FIRST_NAME: {
    regex: /^.{0,50}$/,
    message: 'Enter a valid first name max 50 characters.',
  },
  LAST_NAME: {
    regex: /^.{0,50}$/,
    message: 'Enter a valid last name max 50 characters.',
  },
  NAME_ON_CARD: {
    regex: /^.{0,50}$/,
    message: 'Enter a valid card holder name max 50 characters.',
  },
  CITY: {
    regex: /^.{0,50}$/,
    message: 'Enter a valid city name max 50 characters.',
  },
  COST_PER_DRIVER: {
    regex: /^(?!0(\.0{1,2})?$)\d+(\.\d{1,2})?$/,
    message: 'Enter a valid amount: only non-zero value allowed, up to 2 decimal places.',
  },
  NUMBER_OF_DRIVERS: {
    regex: /^[1-9][0-9]{0,4}$/,
    message: 'Enter a valid number of drivers: numbers, up to 5 characters.',
  },
  PLAN_NAME: {
    regex: /^[a-zA-Z\s]{0,50}$/,
    message: 'Enter a valid plan name: letters only, max 50 characters.',
  },
  INDUSTRY: {
    regex: /^.{0,50}$/,
    message: 'Enter valid industry: max 50 characters.',
  },
};

export function formNoWhitespaceValidator(form: FormGroup) {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    if (control && typeof control.value === 'string') {
      const originalValue = control.value;
      const trimmedValue = originalValue.replace(/^\s+/g, ''); // Trim leading white space
      // Check if the trimmed value is different from the original value
      if (originalValue !== trimmedValue) {
        // Update the control value with the trimmed value
        control.setValue(trimmedValue);
      }
    }
  });
  // Return null to indicate that there are no errors
  return null;
}
