import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  IError,
  IResponse,
  ServiceHeaderData,
} from 'src/app/_types/common.types';
import { UserData } from 'src/app/_types/corporator.types';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { LoaderService } from '../../services/loader.service';
import { NotifierService } from '../../services/notifier.service';
import { SessionService } from '../../services/session.service';
import { SharedDataService } from '../../services/shared-data.service';
import { subscription_status } from 'src/app/constant/db.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isAdmin: boolean = false;
  pathName: string = '';
  private unsubscribe$ = new Subject<void>();
  subscription_status: string = '';
  showDiv: boolean = false;
  userProfileData!: UserData;
  serviceUserData!: UserData;
  constructor(
    public _SharedDataService: SharedDataService,
    private _AuthService: AuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private _ActiveRoute: ActivatedRoute,
    private _OrganizationService: OrganizationService,
    private _SessionService: SessionService,
    private _NotifierService: NotifierService,
    private _LoaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.pathName = this._ActiveRoute.snapshot.routeConfig?.path || '';
    this.isAdmin = this.pathName === 'admin';
    let data = this._SessionService.getSession('data');
    let userId = data ? JSON.parse(data).id : '';
    this.getUserProfile(this.isAdmin, userId);
  }

  logout() {
    this.confirmationDialogService
      .openConfirmationDialog(
        'Logout',
        'Are you sure you want to logout?',
        'confirmation'
      )
      .subscribe((result) => {
        if (result) {
          this._AuthService.logout(true);
        } else {
          console.log('logout cancelled');
        }
      });
  }

  getUserProfile(isAdmin: boolean, userId?: number) {
    this._OrganizationService
      .getUserProfile(isAdmin, userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: IResponse) => {
          if (response.success) {
            this._SessionService.setSession({
              userData: JSON.stringify(response.data),
            });
            this.userProfileData = response.data as UserData | any;

            this.subscription_status =
              this.userProfileData?.subscription?.subscription_status;
            this._SharedDataService
              .getServiceHeaderData()
              .subscribe((headerData) => {
                if (headerData) {
                  this.userProfileData = headerData as ServiceHeaderData | any;
                  this._LoaderService.setState(false);
                }
              });
            this.sendUserData(this.userProfileData);
            if (this.subscription_status !== 'active') {
              this.showDiv = true;
            }
          }
          this._LoaderService.setState(false);
        },
        error: (error: IError) => {
          console.log('Inside error get corporate===>>', error);
          this._NotifierService.showError(error.error?.message);
        },
      });
  }

  sendUserData(userData: UserData): void {
    this._SharedDataService.setSubsData(userData);
  }

  getPlanName(): string {
    this._SharedDataService.getSubsData().subscribe((data) => {
      this.serviceUserData = data;
    });    
    return (
      this.serviceUserData?.subscription?.subscription_status === subscription_status.ACTIVE ||  this.serviceUserData?.subscription?.subscription_status === subscription_status.TRIAL ? 
      this.serviceUserData?.subscription?.plan?.plan_name ||
      this.serviceUserData?.planName ||
      'custom': 'No'
    );
  }
}
